import { ReactNode } from "react";
import * as css from "./style.css";
import { classNames } from "@/lib/class-list";

export function ViewContainer(props: {
  children: ReactNode;
  maxWidth?: boolean;
  padding?: keyof typeof css.padding;
}) {
  const { maxWidth = true, padding = "default", children } = props;

  return (
    <div className={classNames(css.main, css.padding[padding])}>
      <div className={classNames(maxWidth && css.maxWidth)}>{children}</div>
    </div>
  );
}

import { HobgoblinDatabase } from "@/lib/db";
import { ArmyProvider } from "@/ui/army-provider";
import { useQuery } from "@/ui/database-provider/hooks";
import { ReactNode } from "react";

function queryArmy(armyId: string) {
  return async (db: HobgoblinDatabase) => {
    return (await db.getArmy(armyId)) ?? null;
  };
}

export function LocalArmyProvider(props: {
  armyId: string;
  children: ReactNode;
}) {
  const result = useQuery(queryArmy, props.armyId);

  return <ArmyProvider armyDataOp={result}>{props.children}</ArmyProvider>;
}

import { usePathname } from "@/ui/hooks/use-pathname";
import { Link } from "@/ui/link";
import type { AnchorHTMLAttributes, ReactNode } from "react";
import { betaLabel, navLink } from "./style.css";

type Match = "starts-with" | "equal";

/**
 * Checks if a given pathname is exactly equal to or starts with a specified href.
 *
 * Note that this is different from simply using `startsWith`, since it differentiates
 * between e.g. `/example` and `/examples` — both of these start with the string
 * `/example`, but they don't represent the same inital path segment.
 */
function startsWith(pathname: string, href: string) {
  return pathname === href || pathname.startsWith(`${href}/`);
}

function equals(pathname: string, href: string) {
  return pathname === href;
}

export function ToolLink(props: {
  children: ReactNode;
  href: string;
  beta?: boolean;
  match?: Match;
}) {
  const pathname = usePathname();
  const matchers: Record<Match, (p: string, h: string) => boolean> = {
    "starts-with": startsWith,
    equal: equals,
  };

  const checkMatch = matchers[props.match ?? "starts-with"];
  const variant = checkMatch(pathname, props.href) ? "active" : "inactive";

  return (
    <Link className={navLink[variant]} href={props.href}>
      {props.children}
      {props.beta && <span className={betaLabel}>Beta</span>}
    </Link>
  );
}

export function ToolExternalLink(
  props: AnchorHTMLAttributes<HTMLAnchorElement>,
) {
  return <a {...props} className={navLink["inactive"]} />;
}

import { ShareDialog } from "@/ui/share-dialog";
import { BattleState } from "../battle-state/context";
import { DialogStore } from "@ariakit/react";

function getShareLink(state: BattleState) {
  const params = new URLSearchParams();
  const { ruleset, ...sections } = state;

  for (const [key, value] of Object.entries(sections)) {
    if (Array.isArray(value)) {
      for (const subvalue of value) {
        params.append(key, subvalue.id);
      }
    } else if (value) {
      params.set(key, value.id);
    }
  }

  if (!ruleset) {
    throw new Error("Ruleset version must be specified.");
  }

  params.set("ruleset_version", ruleset.version);

  return `${window.origin}/battle?${params}`;
}

export function ShareBattleDialog(props: {
  store: DialogStore;
  battleState: BattleState;
}) {
  const url = getShareLink(props.battleState);

  return (
    <ShareDialog
      heading="Share Battle"
      store={props.store}
      inputValue={url}
      shareData={{
        title: "Chaos of War",
        url: url,
      }}
      text="Use this link to share this battle setup with anyone on the internet."
    />
  );
}

import { Army } from "@/domain/army";
import { caughtValueToString } from "@/lib/caught-value";
import { ArmyData } from "@/schema/latest";
import { Button } from "@/ui/button";
import { ConfirmDialog } from "@/ui/confirm-dialog";
import { useDatabase } from "@/ui/database-provider";
import { KnownErrorMessage } from "@/ui/db-error";
import {
  RulesetProvider,
  useGameContentLookup,
} from "@/ui/game-content-provider";
import { useDialog } from "@/ui/hooks/use-dialog";
import { useAsyncOp } from "@/ui/hooks/use-async-op";
import { useRouter } from "@/ui/hooks/use-router";
import { LoadingIndicator } from "@/ui/loading-indicator";
import { MobileNavTitle } from "@/ui/mobile-nav-title";
import { BackButton, Nav } from "@/ui/nav";
import { SidenavTitle } from "@/ui/sidenav-title";
import { ToolLayoutV2 } from "@/ui/tool-layout";
import { button, primaryTheme } from "@/ui/utils.css";
import { DialogStore } from "@ariakit/react";
import { useEffect } from "react";
import { Route, Switch } from "wouter";
import { ArmyDetailUnitCard, ArmyPageLayout } from "../army-detail-page";
import { useArmyOp } from "../army-provider/context";
import { SharedUnitDetailView } from "../unit-detail-page";

type TransportError = { type: "FETCH_ERROR"; error: string };

export function useFetchSharedArmy(shareId: string) {
  const game = useGameContentLookup();
  const { op, setSuccess, setFailure } = useAsyncOp<
    ArmyData,
    TransportError | { type: "ARMY_NOT_FOUND"; message?: string }
  >();

  useEffect(() => {
    async function getSharedArmy() {
      try {
        const response = await fetch(`/api/shares?id=${shareId}`);

        if (!response.ok) {
          throw new Error("Could not fetch Shared Army.");
        }

        const data: ArmyData | null = await response.json();

        if (data) {
          setSuccess(data);
        } else {
          setFailure({
            type: "ARMY_NOT_FOUND",
            message: "Shared army with the specified ID was not found.",
          });
        }
      } catch (error) {
        console.error(error);

        setFailure({
          type: "FETCH_ERROR",
          error: caughtValueToString(error),
        });
      }
    }

    void getSharedArmy();
  }, [shareId, setSuccess, setFailure, game]);

  return op;
}

function DuplicateArmyDialog(props: { store: DialogStore; army: Army }) {
  const setLocation = useRouter();
  const database = useDatabase();

  return (
    <ConfirmDialog
      store={props.store}
      heading="Duplicate Army?"
      message={`${props.army.name} will be copied over to your armies.`}
      submit="Duplicate"
      submitTheme={primaryTheme}
      onSubmit={async () => {
        const armyId = await database.createArmy(props.army.data);
        setLocation(`/army/a/${armyId}`);
      }}
    />
  );
}

function DuplicateArmyButton(props: { army: Army }) {
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && <DuplicateArmyDialog store={store} army={props.army} />}
      <Button className={button} onClick={setOpen} style={primaryTheme}>
        Duplicate Army
      </Button>
    </>
  );
}

function SharedArmyDetailPageView(props: { shareId: string }) {
  const armyQuery = useArmyOp();

  if (armyQuery.isPending) {
    return <LoadingIndicator />;
  }

  if (armyQuery.isFailure) {
    return <KnownErrorMessage error={armyQuery.failure} />;
  }

  const army = armyQuery.value;

  return (
    <RulesetProvider ruleset={army.ruleset}>
      <ArmyPageLayout
        armyPath="/army/s/:armyId"
        unitPath="/army/s/:armyId/u/:unitId"
        headerLabel="Shared Army"
        army={army}
        unitDetailComponent={SharedUnitDetailView}
        renderUnit={(unit) => {
          return (
            <ArmyDetailUnitCard
              key={unit.id}
              href={`/army/s/${props.shareId}/u/${unit.id}`}
              unit={unit}
            />
          );
        }}
        stickyButton={<DuplicateArmyButton army={army} />}
      />
    </RulesetProvider>
  );
}

function SharedUnitNav(props: { params: { armyId: string; unitId: string } }) {
  return (
    <Nav
      title={<MobileNavTitle title="Shared Unit" />}
      menu={<BackButton href={`/army/s/${props.params.armyId}`} />}
      action={null}
    >
      <SidenavTitle>Army Builder</SidenavTitle>
    </Nav>
  );
}

function SharedArmyNav() {
  return (
    <Nav
      title={<MobileNavTitle title="Shared Army" />}
      menu={<BackButton href="/army" />}
      action={null}
    >
      <SidenavTitle>Army Builder</SidenavTitle>
    </Nav>
  );
}

export function SharedArmyDetailPage(props: { params: { shareId: string } }) {
  return (
    <ToolLayoutV2
      nav={
        <Switch>
          <Route path="/army/s/:armyId/u/:unitId" component={SharedUnitNav} />
          <Route path="/army/s/:armyId" component={SharedArmyNav} />
        </Switch>
      }
    >
      <SharedArmyDetailPageView shareId={props.params.shareId} />
    </ToolLayoutV2>
  );
}

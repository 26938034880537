import { Ruleset } from "@/domain/ruleset";
import { SettingsData } from "@/schema/latest";

export const sectionIdToKey: Record<SectionId, SectionExclusionKey> = {
  scenario: "excluded_scenarios_ids",
  battlefield: "excluded_battlefields_ids",
  deployment: "excluded_deployments_ids",
  twist: "excluded_twists_ids",
};

export type SectionId = "scenario" | "battlefield" | "deployment" | "twist";

export type SectionExclusionKey =
  | "excluded_scenarios_ids"
  | "excluded_battlefields_ids"
  | "excluded_deployments_ids"
  | "excluded_twists_ids";

export class UserSettings {
  readonly data: SettingsData;
  readonly ruleset: Ruleset;

  constructor(ruleset: Ruleset, data: SettingsData) {
    this.data = data;
    this.ruleset = ruleset;
  }

  get excludedScenariosIds() {
    return this.data.excluded_scenarios_ids;
  }

  get excludedBattlefieldsIds() {
    return this.data.excluded_battlefields_ids;
  }

  get excludedDeploymentsIds() {
    return this.data.excluded_deployments_ids;
  }

  get excludedTwistsIds() {
    return this.data.excluded_twists_ids;
  }

  getExcluded(sectionId: SectionId) {
    return this.data[sectionIdToKey[sectionId]];
  }
}

import { ArmyData } from "@/schema/latest";
import { useEnsureShareLink } from "@/ui/hooks/use-ensure-share-link";
import { ShareDialog } from "@/ui/share-dialog";
import { DialogStore } from "@ariakit/react";

export function ShareArmyDialog(props: {
  armyShareTitle: string;
  armyData: ArmyData;
  store: DialogStore;
}) {
  const shareLinkOp = useEnsureShareLink(props.armyData);

  const linkValue = shareLinkOp.val ?? "Generating unique link...";

  const shareData = shareLinkOp
    .mapSuccess((url) => ({ title: props.armyShareTitle, url }))
    .valueOrNull();

  const armyName = props.armyData.name || "this army";

  return (
    <ShareDialog
      store={props.store}
      heading="Share Army"
      inputValue={linkValue}
      shareData={shareData}
      text={`Share this version of ${armyName} with your friends and enemies! If you make changes, you will need to generate a new link for others to see them.`}
    />
  );
}

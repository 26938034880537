import cross from "@/images/cross.svg";
import { Image } from "@/ui/image";
import { KeywordsHandler } from "@/ui/keywords-handler";
import { DialogDismiss, DialogStore } from "@ariakit/react";
import { ReactNode } from "react";
import { ModalDialog } from "../modal-dialog";
import { primaryTheme } from "../utils.css";
import * as css from "./style.css";

export function FormDialog(props: {
  store: DialogStore;
  heading: string;
  subheading?: string;
  onSubmit?: () => Promise<void>;
  submit: string;
  children: ReactNode;
  disabled?: boolean;
}) {
  return (
    <ModalDialog store={props.store} className={css.dialog}>
      <KeywordsHandler>
        <form
          className={css.content}
          onSubmit={async (event) => {
            event.preventDefault();

            if (!props.disabled) {
              try {
                await props.onSubmit?.();
                props.store.hide();
              } catch (error) {
                console.error(error);
              }
            }
          }}
        >
          <div className={css.header}>
            <div />
            <div>
              <h2 className={css.heading}>{props.heading}</h2>

              {props.subheading && (
                <div className={css.subheading}>{props.subheading}</div>
              )}
            </div>

            <DialogDismiss>
              <Image src={cross} alt="Dismiss" width={40} height={40} />
            </DialogDismiss>
          </div>

          {props.children}

          <div className={css.submitButtonContainer}>
            <button
              type="submit"
              className={css.submitButton}
              style={primaryTheme}
              disabled={props.disabled}
            >
              {props.submit}
            </button>
          </div>
        </form>
      </KeywordsHandler>
    </ModalDialog>
  );
}

export type SpellData = {
  name: string;
  description: string;
  type: string;
};

export class Spell {
  name: string;
  description: string;
  type: string;

  constructor(data: SpellData) {
    this.name = data.name;
    this.description = data.description;
    this.type = data.type;
  }

  get descriptionWithType() {
    return this.description.replace("<p>", `<p>${this.typeLabel}. `);
  }

  get typeLabel() {
    switch (this.type) {
      case "CANTRIP":
        return "Cantrip (2)";

      case "MAKING":
        return "Making (4)";

      case "CATACLYSM":
        return "Cataclysm (6)";

      default:
        return "Unrecognised Spell Type";
    }
  }
}

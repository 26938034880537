import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

export async function fetcher(url: string) {
  const response = await fetch(url);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return await response.json();
}

export function useFetcher<T = unknown>(url: string) {
  return useSWR<T, unknown>(url, fetcher);
}

export function useImmutableFetcher<T = unknown>(url: string) {
  return useSWRImmutable<T, unknown>(url, fetcher);
}

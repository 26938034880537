import { createUsePersistedState } from "./use-persisted-state";

type Storage = {
  join_prompt_dismissed_at: number | null;
  safari_install_prompt_dismissed_at: number | null;
};

export const usePromptState = createUsePersistedState<Storage>({
  join_prompt_dismissed_at: null,
  safari_install_prompt_dismissed_at: null,
});

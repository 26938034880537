import { ArmyRecordData } from "@/schema/latest";
import { useCallback, useSyncExternalStore } from "react";

export type LogItemWithoutTimestamp =
  | { type: "PULL_SUCCESS"; records: ArmyRecordData[] }
  | { type: "PUSH_SUCCESS"; records: ArmyRecordData[] }
  | { type: "PUSH_NO_CHANGES" }
  | { type: "PULL_NO_CHANGES" }
  | { type: "SYNC_ERROR"; error: string };

export type LogItem = LogItemWithoutTimestamp & { ts: number };

class SyncStorage extends EventTarget {
  private SYNC_LOG = "sync_log";
  private LAST_SYNC_AT = "last_sync_at";
  private SHOULD_SYNC = "should_sync";

  // React's useSyncExternalStore needs immutable data. This means
  // we can't JSON.parse on every access.
  private cache: Map<string, unknown> = new Map();

  private emitChange() {
    this.dispatchEvent(new Event("change"));
  }

  private getJsonItem<T>(key: string, fallback: T) {
    // First check cache
    const cachedValue = this.cache.get(key);
    if (cachedValue !== undefined) {
      return cachedValue as T;
    }

    // Now check local storage
    const value = localStorage.getItem(key);
    if (typeof value === "string") {
      const parsedValue = JSON.parse(value) as T;
      this.cache.set(key, parsedValue);
      return parsedValue;
    }

    // Set and return fallback otherwise
    this.cache.set(key, fallback);
    return fallback;
  }

  private setJsonItem<T>(key: string, item: T) {
    localStorage.setItem(key, JSON.stringify(item));
    this.cache.set(key, item);
    this.emitChange();
  }

  get isSyncing() {
    const value = this.cache.get("isSyncing") as boolean | undefined;
    if (value !== undefined) {
      return value;
    }

    this.cache.set("isSyncing", false);
    return value ?? false;
  }
  set isSyncing(value) {
    this.cache.set("isSyncing", value);
    this.emitChange();
  }

  get isSyncEnabled() {
    return this.getJsonItem<boolean>(this.SHOULD_SYNC, false);
  }
  set isSyncEnabled(value) {
    this.setJsonItem(this.SHOULD_SYNC, value);
  }

  get lastSuccessfulSyncTs() {
    return this.getJsonItem<number | null>(this.LAST_SYNC_AT, null);
  }
  set lastSuccessfulSyncTs(ts) {
    this.setJsonItem(this.LAST_SYNC_AT, ts);
  }

  get syncLog() {
    return this.getJsonItem<LogItem[]>(this.SYNC_LOG, []);
  }
  set syncLog(items) {
    this.setJsonItem<LogItem[]>(this.SYNC_LOG, items);
  }

  /**
   * Prepends supplied `LogItem`, adds date, and persists it in `localStorage`.
   *
   * Items will be added to the front of the list. If there are more than
   * 15 items, the oldest ones will be dropped.
   */
  log(item: LogItemWithoutTimestamp) {
    console[item.type === "SYNC_ERROR" ? "warn" : "info"](item);
    const items = this.syncLog.slice(0, 14);
    this.syncLog = [{ ...item, ts: Date.now() }, ...items];
  }
}

export const syncStorage = new SyncStorage();

function subscribe(callback: () => void) {
  syncStorage.addEventListener("change", callback);
  return () => {
    syncStorage.removeEventListener("change", callback);
  };
}

/**
 * Returns keys that lead to non-function values.
 */
type ValueKey<
  T extends object,
  K extends string = keyof T & string,
> = K extends keyof T ?
  // eslint-disable-next-line @typescript-eslint/ban-types
  T[K] extends Function ?
    never
  : K
: never;

export function useObserveSyncStorage<K extends ValueKey<SyncStorage>>(key: K) {
  const getSnapshot = useCallback(() => syncStorage[key], [key]);
  return useSyncExternalStore(subscribe, getSnapshot);
}

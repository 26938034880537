import { Pending } from "@/lib/async-op";
import { QueryOp } from "@/ui/database-provider/types";
import { createContext, useContext } from "react";

export const ArmiesRulesetsContext = createContext<QueryOp<Set<string>>>(
  new Pending(),
);

export function useArmiesRulesets() {
  return useContext(ArmiesRulesetsContext);
}

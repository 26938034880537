import { Army } from "@/domain/army";
import { HobgoblinDatabase } from "@/lib/db";
import { fold } from "@/lib/async-op";
import { UserSettings } from "@/lib/user-settings";
import { ArmyData } from "@/schema/latest";
import { useQuery } from "@/ui/database-provider";
import { KnownErrorMessage } from "@/ui/db-error";
import { useResolveVersion } from "@/ui/game-content-provider";
import { Link } from "@/ui/link";
import { LoadingIndicator } from "@/ui/loading-indicator";
import { MobileNavTitle } from "@/ui/mobile-nav-title";
import { useSession } from "@/ui/session-provider/context";
import { SidenavTitle } from "@/ui/sidenav-title";
import { MaybeSignupPrompt } from "@/ui/signup-prompt";
import { useObserveSyncStorage } from "@/ui/sync-handler/sync-storage";
import { ToolLayout } from "@/ui/tool-layout";
import { useUserSettingsOp } from "@/ui/user-settings/context";
import { ArmiesPageEmptyState } from "../armies-page-empty-state";
import { ListView } from "../list-view";
import { CreateArmyButton } from "./create-army-button";
import * as css from "./style.css";

function SyncStatus(props: { updatedTs: number }) {
  const { isLoggedIn } = useSession();
  const lastSyncTs = useObserveSyncStorage("lastSuccessfulSyncTs") ?? 0;
  const isSyncing = useObserveSyncStorage("isSyncing");

  // If user is not logged in, don't show sync status
  if (!isLoggedIn) {
    return null;
  }

  // User is logged in, but sync was completed after update of the entity.
  // Nothing to show.
  if (lastSyncTs > props.updatedTs) {
    return null;
  }

  return isSyncing ? " (Syncing...)" : " (Not synced)";
}

function ArmyListItem(props: {
  armyData: ArmyData;
  userSettings: UserSettings;
}) {
  const { armyData, userSettings } = props;
  const versionOp = useResolveVersion(armyData.ruleset_version);
  const armyStatsOp = versionOp.mapSuccess(
    (ruleset) => new Army(ruleset, armyData),
  );

  return (
    <Link className={css.army} href={`/army/a/${armyData.id}`}>
      <h2 className={css.armyHeading}>{armyData.name || Army.FALLBACK_NAME}</h2>
      <p className={css.armyStats}>
        {armyStatsOp.unpack(
          (army) => {
            const usesDefaultRuleset =
              army.ruleset.version === userSettings.ruleset.version;

            return (
              <>
                {!army.isValid() && (
                  <>
                    <span style={{ color: "red" }}>Invalid</span>
                    {" · "}
                  </>
                )}
                {army.summary}
                {!usesDefaultRuleset &&
                  ` · Ruleset: ${army.ruleset.versionName}`}

                <SyncStatus {...army} />
              </>
            );
          },
          () => {
            return (
              <span style={{ color: "red" }}>
                Failed to fetch army ruleset (v{armyData.ruleset_version})
              </span>
            );
          },
          () => "Loading army ruleset...",
        )}
      </p>
    </Link>
  );
}

function queryArmies() {
  return (db: HobgoblinDatabase) => db.getArmies();
}

function ArmiesPageSwitch() {
  const armiesQuery = useQuery(queryArmies);
  const userSettingsOp = useUserSettingsOp();
  const op = fold([armiesQuery, userSettingsOp]);

  if (op.isPending) {
    return <LoadingIndicator />;
  }

  if (op.isFailure) {
    return <KnownErrorMessage error={op.failure} />;
  }

  const [armiesData, userSettings] = op.value;

  if (armiesData.length === 0) {
    return (
      <ArmiesPageEmptyState rulesetVersion={userSettings.ruleset.version} />
    );
  }

  const items = [
    <MaybeSignupPrompt key="prompt" armiesCount={armiesData.length} />,

    ...armiesData.map((armyData) => {
      return (
        <ArmyListItem
          key={armyData.id}
          armyData={armyData}
          userSettings={userSettings}
        />
      );
    }),
  ];

  return (
    <ListView
      items={items}
      stickyButton={
        <CreateArmyButton rulesetVersion={userSettings.ruleset.version} />
      }
    />
  );
}

export function ArmiesPage() {
  return (
    <ToolLayout
      title={<MobileNavTitle title="Army Builder" />}
      sidenav={<SidenavTitle>Army Builder</SidenavTitle>}
    >
      <ArmiesPageSwitch />
    </ToolLayout>
  );
}

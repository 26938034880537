import { Body } from "@/ui/body";
import { Image } from "@/ui/image";
import * as css from "./style.css";

export function FortuneCard(props: {
  cardId: string;
  name: string;
  description: string;
  label: string;
  onClick: () => void;
  buttonLabel: string;
  isUsed?: boolean;
}) {
  return (
    <div className={css.card}>
      <div>
        <h2 className={css.heading}>{props.name}</h2>
        <Body html={props.description} />
      </div>

      <div className={css.buttonContainer}>
        <button
          type="button"
          className={css.button}
          onClick={() => {
            props.onClick();
          }}
          disabled={props.isUsed}
        >
          <Image
            src={`/cards/${props.cardId}.svg`}
            alt=""
            width={225}
            height={315}
            className={css.cardImage}
          />
          <div>
            <div className={css.buttonLabel}>
              {props.isUsed ? "Already in hand" : props.buttonLabel}
            </div>
            <div className={css.playingCardLabel}>{props.label}</div>
          </div>
        </button>
      </div>
    </div>
  );
}

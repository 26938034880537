export function sortByName<T extends { name: string }>(a: T, b: T): number {
  if (a.name > b.name) {
    return 1;
  }

  if (b.name > a.name) {
    return -1;
  }

  return 0;
}

export function formatRolls(rolls: number[]) {
  if (rolls.length === 1) {
    return `${rolls[0]}`;
  }

  const min = Math.min(...rolls);
  const max = Math.max(...rolls);
  return `${min}-${max}`;
}

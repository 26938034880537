import { ArmyData } from "../schema/latest.js";
import { pluralize } from "../lib/text.js";
import { Ruleset } from "./ruleset.js";
import { uniqueById } from "./unique-by-id.js";
import { Unit } from "./unit.js";
import { sortByName } from "./utils.js";
import { ArmyValidationError } from "./validation.js";

export class Army {
  readonly id: string;
  readonly description: string;
  readonly createdTs: number;
  readonly updatedTs: number;
  readonly shareId: string | null;
  readonly imageId?: string;
  readonly units: Unit[];
  readonly data: ArmyData;
  readonly issues: ArmyValidationError[];
  readonly ruleset: Ruleset;

  constructor(ruleset: Ruleset, data: ArmyData) {
    this.ruleset = ruleset;
    this.id = data.id;
    this.description = data.description;
    this.createdTs = data.created_ts;
    this.updatedTs = data.updated_ts;
    this.shareId = data.share_id;
    this.imageId = data.image_id;
    this.data = data;
    this.issues = [];
    this.units = data.units
      .map((unitData) => new Unit(this, unitData))
      .sort((a, b) => Unit.sortByRank(a, b));

    this.validateArmy();
  }

  static readonly FALLBACK_NAME = "Unnamed Army";

  get name() {
    return this.data.name || Army.FALLBACK_NAME;
  }

  get points() {
    return this.units.reduce((sum, unit) => sum + unit.points * unit.count, 0);
  }

  get unitsCount() {
    return this.units.reduce((sum, unit) => sum + unit.count, 0);
  }

  get summary() {
    if (this.unitsCount === 0) {
      return "No units";
    }

    return `${this.points}pts, ${pluralize(this.unitsCount, "unit", "units")}`;
  }

  get cursedArtefactsDefinitions() {
    return uniqueById(this.units.flatMap((u) => u.cursedArtefacts)).sort(
      sortByName,
    );
  }

  get keywordsDefinitions() {
    return uniqueById(
      this.units.flatMap((u) => u.keywords.map((k) => k.definition)),
    ).sort(sortByName);
  }

  get abyssalAllegiancesDefinitions() {
    return uniqueById(
      this.units.flatMap((u) => u.abyssalAllegiances.map((a) => a.definition)),
    ).sort(sortByName);
  }

  getUnit(unitId: string) {
    return this.units.find((u) => u.id === unitId);
  }

  isValid(this: Army) {
    return this.issues.length === 0;
  }

  private validateArmy(this: Army) {
    let hasGeneral = false;
    let hasBattleStandard = false;

    for (const unit of this.units) {
      if (unit.hasKeyword("general")) {
        hasGeneral = true;
      }

      if (unit.hasKeyword("battle_standard")) {
        hasBattleStandard = true;
      }
    }

    if (!hasGeneral) {
      this.issues.push({ type: "MISSING_GENERAL" });
    }

    if (!hasBattleStandard) {
      this.issues.push({ type: "MISSING_BATTLE_STANDARD" });
    }

    const invalidUnits = this.units.filter((unit) => !unit.isValid());

    if (invalidUnits.length > 0) {
      this.issues.push({ type: "INVALID_UNITS", units: invalidUnits });
    }
  }
}

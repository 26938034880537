import { Battlefield } from "@/domain/battlefield";
import { Deployment } from "@/domain/deployment";
import { Ruleset } from "@/domain/ruleset";
import { Scenario } from "@/domain/scenarios";
import { Twist } from "@/domain/twist";
import { AsyncOp, Pending } from "@/lib/async-op";
import { VersionResolutionError } from "@/lib/game-system";
import {
  InaccessibleDatabaseError,
  QueryError,
} from "@/ui/database-provider/types";
import { createContext, useContext } from "react";
import { SectionId } from "../../lib/user-settings";

export type BattleState = {
  ruleset: Ruleset;
  scenario: Scenario | null;
  battlefield: Battlefield[] | null;
  deployment: Deployment | null;
  twist: Twist | null;
};

export type BattleActions = {
  rerollAll: () => void;
  rerollSection: (id: SectionId) => void;
};

export const BattleStateContext = createContext<
  AsyncOp<
    { state: BattleState; actions: BattleActions },
    QueryError | InaccessibleDatabaseError | VersionResolutionError
  >
>(new Pending());

export function useBattleStateOp() {
  return useContext(BattleStateContext);
}

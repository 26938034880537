import { ReactNode } from "react";
import * as css from "./style.css";

export function DesktopViewHeader(props: { menu: ReactNode; label: string }) {
  return (
    <header className={css.header}>
      <div className={css.label}>{props.label}</div>

      {props.menu}
    </header>
  );
}

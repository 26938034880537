import { HamburgerButton, Nav } from "@/ui/nav";
import { ReactNode } from "react";
import * as css from "./style.css";

export function ToolLayout(props: {
  children: ReactNode;
  title: ReactNode;
  sidenav: ReactNode;
  menu?: ReactNode;
  action?: ReactNode;
}) {
  return (
    <div className={css.root}>
      <Nav
        title={props.title}
        action={props.action}
        menu={props.menu ?? <HamburgerButton />}
      >
        {props.sidenav}
      </Nav>

      {props.children}
    </div>
  );
}

export function ToolLayoutV2(props: { children: ReactNode; nav: ReactNode }) {
  return (
    <div className={css.root}>
      {props.nav}
      {props.children}
    </div>
  );
}

import { config } from "@/config";
import { Configuration, FrontendApi } from "@ory/client";
import { type, number, string } from "superstruct";

export const ory = new FrontendApi(
  new Configuration({
    basePath: config.ORY_SDK_URL,
    baseOptions: {
      withCredentials: true,
      headers: { accept: "application/json" },
    },
  }),
);

export function getLoginUrl() {
  const params = new URLSearchParams({ return_to: window.location.origin });
  return `${config.ORY_SDK_URL}/self-service/login/browser?${params}`;
}

export const oryErrorStruct = type({
  response: type({
    data: type({
      error: type({
        code: number(),
        status: string(),
        reason: string(),
        message: string(),
      }),
    }),
  }),
});

export const color = {
  DARK_GRAY: "#232424",
  GRAY: "#ECECEC",
  LIGHT_GREY: "#E7E8E8",
  PALE_GREY: "#F6F7F7",
  BLUE_GREY: "#4F5461",
  MID_GREY: "hsl(0 0% 50%)",
  GOLD: "#E3D786",
};

export const tokens = {
  CARD_COLOR: "white",
  BACKGROUND_COLOR: color.PALE_GREY,
  BUTTON_COLOR: color.BLUE_GREY,
  BUTTON_LABEL_COLOR: "white",
  SUBTLE_BUTTON_COLOR: "#DEDEDE",
};

import {
  KeywordEffect,
  KeywordPurchaseOption,
  KeywordType,
} from "./keyword-types.js";
import { Trait, injectTraits, toTrait } from "./traits.js";

export type KeywordDefinitionData = {
  id: string;
  name: string;
  description: string;
  traits: string[];
  effects?: any[];
  purchase_options?: any[];
};

export class KeywordDefinition {
  id: string;
  name: string;
  description: string;
  type: KeywordType;
  traits: Trait[];
  effects: KeywordEffect[];
  purchaseOptions: KeywordPurchaseOption[];
  data: KeywordDefinitionData;

  constructor(data: KeywordDefinitionData) {
    this.data = data;
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.type =
      data.name.endsWith("[x]") ?
        KeywordType.NUMERICAL_KEYWORD
      : KeywordType.KEYWORD;
    this.traits = data.traits.map(toTrait);
    this.effects = (data.effects as KeywordEffect[]) ?? [];
    this.purchaseOptions = data.purchase_options ?? [];
  }

  get descriptionWithTraits() {
    return injectTraits(this.description, this.traits);
  }

  hasTrait(this: KeywordDefinition, trait: Trait) {
    return this.traits.some((t) => t === trait);
  }

  isNumerical(this: KeywordDefinition) {
    return this.type === KeywordType.NUMERICAL_KEYWORD;
  }
}

import {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from "react";

type Field = {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export function useField(
  initialValue?: string,
): [Field, string, Dispatch<SetStateAction<string>>] {
  const [value, setValue] = useState(initialValue ?? "");

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback((event) => {
      setValue(event.target.value);
    }, []);

  return [{ value, onChange }, value, setValue];
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-fallthrough */

import { VersionChangeManager } from "@/oss/modern-idb";
import { migrateToV2 } from "./migration-v2";
import { migrateToV3 } from "./migration-v3";
import { migrateToV4 } from "./migration-v4";

export type VersionChangeProps = {
  event: IDBVersionChangeEvent;

  /**
   * Individual migration functions should correctly type their version change
   * managers. Inside the top level migration handler, we therefore keep the
   * typings intentionally loose.
   */
  manager: VersionChangeManager<any, any>;
};

export async function migrateDatabase({ manager, event }: VersionChangeProps) {
  switch (event.oldVersion) {
    case 1: {
      await migrateToV2(manager);
    }

    case 2: {
      await migrateToV3(manager);
    }

    case 3: {
      await migrateToV4(manager);
    }

    default: {
      console.info("Migrations complete");
    }
  }
}

import { MobileNavTitle } from "@/ui/mobile-nav-title";
import { BackButton } from "@/ui/nav";
import { SidenavTitle } from "@/ui/sidenav-title";
import { ToolLayout } from "@/ui/tool-layout";
import { Route, Switch } from "wouter";
import { ErrorMessage } from "../error-message";
import { LoadingIndicator } from "../loading-indicator";
import { SidenavLink } from "../sidenav-link";
import { Image } from "@/ui/image";
import * as css from "./style.css";
import { Link } from "../link";
import { useFetcher } from "../hooks/use-fetcher";

export function CoverImage(props: { src: string }) {
  return (
    <Image
      className={css.coverImage}
      src={props.src}
      width={1875}
      height={912}
      alt=""
    />
  );
}

export function ListingItem(props: {
  href: string;
  name: string;
  createdAt?: Date | null;
  coverImageSrc?: string;
  showCover?: boolean;
}) {
  const date = (props.createdAt ?? new Date("2023-06-25")).toLocaleDateString(
    "en-GB",
    {
      dateStyle: "long",
    },
  );

  return (
    <Link href={props.href} className={css.changelogItem}>
      {props.showCover && props.coverImageSrc && (
        <CoverImage src={props.coverImageSrc} />
      )}
      <h2 className={css.changelogItemHeading}>{props.name}</h2>
      <div className={css.changelogItemDate}>{date}</div>
    </Link>
  );
}

export function createDocsPages<
  T extends { id: string; href: string },
>(config: {
  /**
   * Mobile and sidebar navigation title.
   */
  title: string;

  /**
   * This determines the path at which the component expects to
   * be mounted, as well as the path from which data will be fetched.
   */
  rootPath: string;

  renderListingPage: (pages: T[]) => JSX.Element;
  renderPage: (page: T) => JSX.Element;
}) {
  function DocsListing() {
    const { data, error } = useFetcher<T[]>(
      `/docs${config.rootPath}/index.json`,
    );

    if (data) {
      return config.renderListingPage(data);
    }

    if (error) {
      return <ErrorMessage message="Could not load this page" />;
    }

    return <LoadingIndicator />;
  }

  function DocsListingLayout() {
    return (
      <ToolLayout
        title={<MobileNavTitle title={config.title} />}
        sidenav={<SidenavTitle>{config.title}</SidenavTitle>}
      >
        <DocsListing />
      </ToolLayout>
    );
  }

  function Doc(props: { pageId: string }) {
    const { data, error } = useFetcher<T>(
      `/docs${config.rootPath}/${props.pageId}.json`,
    );

    if (data) {
      return config.renderPage(data);
    }

    if (error) {
      return <ErrorMessage message="Could not load this page" />;
    }

    return <LoadingIndicator />;
  }

  function DocLayout(props: { params: { pageId: string } }) {
    return (
      <ToolLayout
        title={<MobileNavTitle title={config.title} />}
        menu={<BackButton href={config.rootPath} />}
        sidenav={
          <>
            <SidenavTitle>{config.title}</SidenavTitle>
            <SidenavLink href={config.rootPath}>
              Back to {config.title}
            </SidenavLink>
          </>
        }
      >
        <Doc pageId={props.params.pageId} />
      </ToolLayout>
    );
  }

  function DocsPagesSwitch() {
    return (
      <Switch>
        <Route path={`${config.rootPath}/:pageId`} component={DocLayout} />
        <Route path={config.rootPath} component={DocsListingLayout} />
      </Switch>
    );
  }

  DocsPagesSwitch.path = `${config.rootPath}/:rest*`;

  return DocsPagesSwitch;
}

import { useScrollToTop } from "@/ui/hooks/use-scroll-to-top";
import { RootLayout } from "@/ui/root-layout";
import { RootPage } from "@/ui/root-page";
import { ToolsPages } from "@/ui/tools-pages";
import { Route, Switch } from "wouter";
import { ChangelogPages } from "../changelog";
import { HelpPages } from "../help";
import { UnauthenticatedPage } from "../unauthenticated-page";
import { Suspense } from "react";
import { LoadingIndicator } from "../loading-indicator";

export function App() {
  useScrollToTop();

  return (
    <RootLayout>
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <Route path="/" component={RootPage} />
          <Route path={ChangelogPages.path} component={ChangelogPages} />
          <Route path={HelpPages.path} component={HelpPages} />
          <Route path="/unauthenticated" component={UnauthenticatedPage} />
          <Route component={ToolsPages} />
        </Switch>
      </Suspense>
    </RootLayout>
  );
}

import { caughtValueToString } from "@/lib/caught-value";
import { HobgoblinDatabase } from "@/lib/db";
import { useAsyncOp } from "@/ui/hooks/use-async-op";
import { useContext, useEffect } from "react";
import { DatabaseContext, DatabaseOpenRequest } from "./context";
import { QueryError, QueryOp } from "./types";

export function useDatabase() {
  return useContext(DatabaseContext);
}

export function useDatabaseOpenRequest() {
  return useContext(DatabaseOpenRequest);
}

export function useQuery<Output>(
  fetcher: () => (db: HobgoblinDatabase) => Promise<Output>,
): QueryOp<Output>;

export function useQuery<Input, Output>(
  fetcher: (input: Input) => (db: HobgoblinDatabase) => Promise<Output>,
  input: Input,
): QueryOp<Output>;

export function useQuery<Input, Output>(
  getQuery: (input?: Input) => (db: HobgoblinDatabase) => Promise<Output>,
  input?: Input,
): QueryOp<Output> {
  const openRequest = useDatabaseOpenRequest();

  const {
    op: queryOp,
    setSuccess,
    setFailure,
  } = useAsyncOp<Output, QueryError>();

  useEffect(() => {
    async function runQuery() {
      if (openRequest.isSuccess) {
        const query = getQuery(input);

        try {
          const data = await query(openRequest.value);
          setSuccess(data);
        } catch (error) {
          setFailure({
            type: "QUERY_ERROR",
            error: caughtValueToString(error),
          });
        }
      }
    }

    const db = openRequest.valueOrNull();

    db?.addEventListener("readwrite", runQuery);
    window.addEventListener("focus", runQuery);
    void runQuery();

    return () => {
      db?.removeEventListener("readwrite", runQuery);
      window.removeEventListener("focus", runQuery);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRequest, getQuery, JSON.stringify(input)]);

  if (openRequest.isSuccess) {
    return queryOp;
  }

  return openRequest;
}

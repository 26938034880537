import { InaccessibleDatabaseError } from "./types";

export function toKnownError(error: unknown): InaccessibleDatabaseError {
  if (error instanceof Error) {
    switch (error.name) {
      case "OpenRequestBlockedError": {
        return {
          type: "UPGRADE_BLOCKED",
        };
      }

      case "VersionError": {
        return {
          type: "DB_VERSION_HIGHER_THAN_REQUESTED",
          message: error.message,
        };
      }

      default: {
        return {
          type: "ERROR",
          message: error.message,
        };
      }
    }
  }

  return {
    type: "ERROR",
    message: "A non-error object was thrown.",
  };
}

import { Army } from "@/domain/army";
import { HobgoblinDatabase } from "@/lib/db";
import { formatListConjunction } from "@/lib/formatters";
import { capitalize, pluralize } from "@/lib/text";
import { ArmyData, ArmyRecordData } from "@/schema/latest";
import { useQuery } from "@/ui/database-provider/hooks";
import { harbourLight } from "@/ui/fonts.css";
import { Image } from "@/ui/image";
import { useSession } from "@/ui/session-provider/context";
import { getLoginUrl } from "@/ui/session-provider/ory";
import { syncStorage } from "@/ui/sync-handler/sync-storage";
import { color } from "@/ui/tokens";
import { button, inverseTheme, subtleTheme } from "@/ui/utils.css";

function getUnsyncedWarning(armies: ArmyRecordData[]) {
  const names = armies
    // We don't want to bother people about syncing of deletes in this case.
    .filter((a): a is ArmyData => !a.deleted)

    // Make sure all armies have some name
    .map((a) => a.name || Army.FALLBACK_NAME);

  const list = formatListConjunction(names);

  // Nothing to warn about...
  if (list.length === 0) {
    return null;
  }

  return `${capitalize(
    pluralize(
      names.length,
      `army has changes that have not yet been synced (${list}).`,
      `armies have changes that have not yet been synced (${list}).`,
      { stylistic: true },
    ),
  )} These changes will be permanently lost if you log out now.`;
}

function getUnsyncedArmies() {
  return (db: HobgoblinDatabase) => {
    return db.getUpdatedArmiesSince(syncStorage.lastSuccessfulSyncTs);
  };
}

export function UnauthenticatedPage() {
  const unsyncedArmiesOp = useQuery(getUnsyncedArmies);
  const { logOut } = useSession();
  const warning =
    unsyncedArmiesOp.isSuccess && getUnsyncedWarning(unsyncedArmiesOp.value);

  return (
    <div
      style={{
        minHeight: "100lvh",
        backgroundColor: color.DARK_GRAY,
        padding: "2rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <main
        style={{
          margin: "0 auto",
          maxWidth: "32rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "white",
          textAlign: "center",
        }}
      >
        <Image
          src="/logos/logo-small.png"
          width={4126}
          height={2479}
          style={{
            display: "block",
            height: "auto",
            maxWidth: "16rem",
            marginBottom: "2rem",
          }}
        />

        <h1 className={harbourLight} style={{ marginBottom: "2rem" }}>
          Logged Out
        </h1>

        <p>
          You have been logged out due to prolonged inactivity. Log back in to
          get access to your synced armies, or log out to use the app locally
          without sync.
        </p>

        {warning && (
          <p style={{ marginTop: "0.5em" }}>
            <span style={{ color: "#f55415" }}>Warning: </span>
            {warning}
          </p>
        )}

        <div
          style={{
            display: "grid",
            gap: "0.5rem",
            width: "100%",
            marginTop: "2rem",
          }}
        >
          <a className={button} style={inverseTheme} href={getLoginUrl()}>
            Log in
          </a>

          <button
            type="button"
            onClick={logOut}
            className={button}
            style={subtleTheme}
          >
            Log out
          </button>
        </div>
      </main>
    </div>
  );
}

import { BattleSection } from "@/battle/section";
import { SectionsContainer } from "@/battle/sections-container";
import { formatRolls } from "@/domain/utils";
import { fold } from "@/lib/async-op";
import { isString } from "@/lib/typeguards";
import { Body } from "@/ui/body";
import { KnownErrorMessage } from "@/ui/db-error";
import { RulesetProvider } from "@/ui/game-content-provider";
import { Image } from "@/ui/image";
import { KeywordsHandler } from "@/ui/keywords-handler";
import { LoadingIndicator } from "@/ui/loading-indicator";
import { MobileNavTitle } from "@/ui/mobile-nav-title";
import { SidenavTitle } from "@/ui/sidenav-title";
import { ToolLayout } from "@/ui/tool-layout";
import { ReactNode } from "react";
import { useUserSettingsOp } from "../../ui/user-settings/context";
import { RerollAllButton, ShareButton } from "../battle-buttons";
import { BattleStateProvider } from "../battle-state";
import { useBattleStateOp } from "../battle-state/context";
import { ShareBattleButton } from "../share-battle-icon-button";
import { VersionMismatchPopover } from "../version-mismatch-popover";
import * as css from "./style.css";

export function Heading(props: { children: ReactNode }) {
  return <h2 className={css.heading}>{props.children}</h2>;
}

function BattleSidenav() {
  return (
    <>
      <SidenavTitle>Chaos of War</SidenavTitle>

      <div className={css.buttons}>
        <RerollAllButton />
        <ShareButton />
      </div>
    </>
  );
}

function SectionIllustration(props: { entryId: string | string[] }) {
  const illustrations: Record<string, string> = {
    pets: "/images/familiar.webp",
    bloodbath: "/images/ice-zombies.webp",
    scouts: "/images/elf-archer.webp",
  };

  const knownIds = Object.keys(illustrations);

  if (isString(props.entryId) && knownIds.includes(props.entryId)) {
    return (
      <div className={css.illustrationContainer}>
        <Image
          className={css.illustration}
          src={illustrations[props.entryId]}
          width={700}
          height={640}
        />
      </div>
    );
  }

  return null;
}

function Section(props: { children: ReactNode }) {
  return <section className={css.section}>{props.children}</section>;
}

function BattlePageMain() {
  const userSettingsOp = useUserSettingsOp();
  const battleStateOp = useBattleStateOp();
  const op = fold([userSettingsOp, battleStateOp]);

  if (op.isPending) {
    return <LoadingIndicator />;
  }

  if (op.isFailure) {
    return <KnownErrorMessage error={op.failure} />;
  }

  const [userSettings, { state }] = op.value;
  const { scenario, battlefield, deployment, twist, ruleset } = state;

  return (
    <RulesetProvider ruleset={ruleset}>
      <KeywordsHandler>
        <VersionMismatchPopover
          battleStateGameVersion={ruleset.version}
          battleSettingsGameVersion={userSettings.ruleset.version}
        />
        <SectionsContainer>
          <BattleSection sectionId="scenario" heading="Scenario">
            {scenario && (
              <>
                <Heading>
                  {formatRolls(scenario.rolls)}: {scenario.name}
                </Heading>
                <blockquote className={css.flavour}>
                  {scenario.flavour}
                </blockquote>
                <Body html={scenario.instructions} />
                <SectionIllustration entryId={scenario.id} />
              </>
            )}
          </BattleSection>
          <BattleSection sectionId="battlefield" heading="Battlefield">
            {battlefield &&
              battlefield.map((entry) => {
                return (
                  <Section key={entry.id}>
                    <Heading>
                      {formatRolls(entry.rolls)}: {entry.name}
                    </Heading>
                    <Body html={entry.instructions} />
                  </Section>
                );
              })}
          </BattleSection>
          <BattleSection sectionId="deployment" heading="Deployment">
            {deployment && (
              <>
                <Heading>
                  {formatRolls(deployment.rolls)}: {deployment.name}
                </Heading>
                <Body html={deployment.instructions} />
                <SectionIllustration entryId={deployment.id} />
              </>
            )}
          </BattleSection>
          <BattleSection sectionId="twist" heading="Twist">
            {twist && (
              <>
                <Heading>
                  {formatRolls(twist.rolls)}: {twist.name}
                </Heading>
                <Body html={twist.instructions} />
                <SectionIllustration entryId={twist.id} />
              </>
            )}
          </BattleSection>
        </SectionsContainer>
      </KeywordsHandler>
    </RulesetProvider>
  );
}

export function BattlePage() {
  return (
    <BattleStateProvider>
      <ToolLayout
        title={<MobileNavTitle title="Chaos of War" />}
        sidenav={<BattleSidenav />}
        action={<ShareBattleButton />}
      >
        <BattlePageMain />
      </ToolLayout>
    </BattleStateProvider>
  );
}

import { dot1, dot2, dot3, indicator } from "./style.css";

export function LoadingIndicator() {
  return (
    <div className={indicator}>
      <div className={dot1}>•</div>
      <div className={dot2}>•</div>
      <div className={dot3}>•</div>
    </div>
  );
}

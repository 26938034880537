function getCardValue(value: string) {
  switch (value) {
    case "1": {
      return "One";
    }
    case "2": {
      return "Two";
    }
    case "3": {
      return "Three";
    }
    case "4": {
      return "Four";
    }
    case "5": {
      return "Five";
    }
    case "6": {
      return "Six";
    }
    case "7": {
      return "Seven";
    }
    case "8": {
      return "Eight";
    }
    case "9": {
      return "Nine";
    }
    case "10": {
      return "Ten";
    }
    case "J": {
      return "Jack";
    }
    case "Q": {
      return "Queen";
    }
    case "K": {
      return "King";
    }
    default: {
      return "Unknown Value";
    }
  }
}

function getCardSuite(suite: string) {
  switch (suite) {
    case "S": {
      return "Spades";
    }
    case "H": {
      return "Hearts";
    }
    case "C": {
      return "Clubs";
    }
    case "D": {
      return "Diamonds";
    }
    default: {
      return "Unknown Suite";
    }
  }
}

export function formatCard(card: { suite: string; value: string }) {
  return `${getCardValue(card.value)} of ${getCardSuite(card.suite)}`;
}

export function formatCardFromId(cardId: string) {
  const [suite, ...value] = cardId.split("").reverse();
  return formatCard({ value: value.reverse().join(""), suite });
}

import { Link } from "@/ui/link";
import { link } from "./style.css";
import { ReactNode } from "react";

export function SidenavLink(props: { href: string; children: ReactNode }) {
  return (
    <div style={{ textAlign: "center" }}>
      <Link href={props.href} className={link}>
        {props.children}
      </Link>
    </div>
  );
}

import { UnitData } from "../schema/latest.js";
import { LookupMap } from "../lib/lookup.js";
import { sortByImportance } from "../lib/sort.js";
import { AbyssalAllegiance } from "./abyssal-allegiance.js";
import { Army } from "./army.js";
import { CursedArtefact } from "./artefact.js";
import { KeywordReasonType } from "./enums.js";
import {
  FootprintStatModifier,
  KeywordValue,
  NumericalStatModifier,
  TargetScoresModifier,
} from "./keyword-types.js";
import { Keyword } from "./keyword.js";
import { Ruleset } from "./ruleset.js";
import { Trait } from "./traits.js";
import { UnitType } from "./unit-type.js";
import { UnitProfile, stringifyUnitType } from "./units.js";
import {
  DuplicateNonNumericalKeyword,
  TraitCountMaxExceeded,
  UnitValidationError,
} from "./validation.js";

function groupBy<T, K extends string>(
  array: T[],
  getGroupKey: (item: T) => K,
): LookupMap<K, T[]> {
  const map = new LookupMap<K, T[]>();

  for (const item of array) {
    const key = getGroupKey(item);
    const group = map.get(key);
    if (group) {
      group.push(item);
    } else {
      map.set(key, [item]);
    }
  }

  return map;
}

function isDeferred(value: unknown): value is KeywordValue.DEFERRED {
  return value === KeywordValue.DEFERRED;
}

function reduceNumericalModifiers(modifiers: NumericalStatModifier[]) {
  const numbericalModifierReducer = (
    sum: number,
    modifier: NumericalStatModifier,
  ): number => {
    if (isDeferred(modifier.value)) {
      return sum;
    }

    switch (modifier.operation) {
      case "set":
        return modifier.value;
      case "increment":
        return sum + modifier.value;
      case "decrement":
        return sum - modifier.value;
      case "divide":
        return sum / modifier.value;
    }
  };

  return modifiers.reduce(numbericalModifierReducer, 0);
}

export type PointsModifier = {
  value: number;
  reason: string;
};

export class Unit {
  readonly id: string;
  readonly description: string;
  readonly type: UnitType;
  readonly profile: UnitProfile;
  readonly keywordCostMultiplier: number;
  readonly keywords: Keyword[];
  readonly count: number;
  readonly issues: UnitValidationError[];
  readonly ruleset: Ruleset;
  readonly army: Army;
  readonly abyssalAllegiances: AbyssalAllegiance[];
  readonly cursedArtefacts: CursedArtefact[];

  // Source data
  readonly data: UnitData;

  // Modifiers
  pointsModifiers: PointsModifier[];
  speedModifiers: NumericalStatModifier[];
  rangeModifiers: NumericalStatModifier[];
  courageModifiers: NumericalStatModifier[];
  strikesModifiers: NumericalStatModifier[];
  rangedStrikesModifiers: NumericalStatModifier[];
  meleeStrikesModifiers: NumericalStatModifier[];
  footprintModifiers: FootprintStatModifier[];
  rangedTargetScoresModifiers: TargetScoresModifier[];
  meleeTargetScoresModifiers: TargetScoresModifier[];
  abyssalAllegiancesMaxCountModifiers: NumericalStatModifier[];

  constructor(army: Army, data: UnitData) {
    this.army = army;
    this.ruleset = army.ruleset;
    this.id = data.id;
    this.type = data.type;
    this.profile = this.ruleset.unitProfilesByType.getOrThrow(data.type);
    this.description = data.description;
    this.count = data.count ?? 1;
    this.pointsModifiers = [
      {
        value: this.profile.points,
        reason: "Initial unit points cost",
      },
    ];
    this.speedModifiers = [
      {
        value: this.profile.speed,
        reason: "Initial unit speed stat",
        operation: "set",
        stat: "speed",
      },
    ];
    this.rangeModifiers = [
      {
        value: this.profile.range,
        reason: "Initial unit range stat",
        operation: "set",
        stat: "range",
      },
    ];
    this.courageModifiers = [
      {
        value: this.profile.courage,
        reason: "Initial unit courage stat",
        operation: "set",
        stat: "courage",
      },
    ];
    this.strikesModifiers = [
      {
        value: this.profile.strikes,
        reason: "Initial unit strikes stat",
        operation: "set",
        stat: "strikes",
      },
    ];
    this.rangedStrikesModifiers = [];
    this.meleeStrikesModifiers = [];
    this.footprintModifiers = [
      {
        value: this.profile.footprint,
        reason: "Initial unit footprint stat",
        operation: "set",
        stat: "footprint",
      },
    ];
    this.keywords = [];
    this.keywordCostMultiplier = this.profile.keywordsCostMultiplier;
    this.rangedTargetScoresModifiers = [];
    this.meleeTargetScoresModifiers = [];
    this.abyssalAllegiancesMaxCountModifiers = [
      {
        stat: "abyssal_allegiances_max_count",
        value: 1,
        operation: "set",
      },
    ];
    this.cursedArtefacts =
      data.cursed_artefacts?.map((ref) => {
        return CursedArtefact.fromRef(this, ref);
      }) ?? [];

    this.abyssalAllegiances =
      data.abyssal_allegiances?.map((ref) => {
        return AbyssalAllegiance.fromRef(this, ref);
      }) ?? [];
    this.data = data;

    this.issues = [];
    this.unpackKeywords(
      [
        ...this.profile.defaultKeywords.map((keywordRef) =>
          Keyword.fromKeywordRefData(this, keywordRef, this),
        ),
        ...this.cursedArtefacts.flatMap((artefact) =>
          artefact.definition.keywordsRefs.map((ref) => {
            return Keyword.fromKeywordRefData(this, ref, artefact);
          }),
        ),
        ...this.data.keywords.map((keywordRef) =>
          // null source represents that the keyword was assigned or purchased
          // by the user directly
          Keyword.fromKeywordRefData(this, keywordRef, null),
        ),
      ],
      this.army.ruleset,
    );
    this.applyKeywordsCost();
    this.applyKeywordsModifiers();
    this.sortKeywords();

    this.validate();
  }

  private validate() {
    this.validateKeywords();
    this.validateAbyssalAllegiances();
    this.validateCursedArtefacts();
  }

  private validateAbyssalAllegiances() {
    const count = this.abyssalAllegiances.length;
    const hasWizard = this.hasKeyword("wizard");

    // No Abyssal Allegiances allowed if no Wizard keyword is present
    if (!hasWizard && count > 0) {
      this.issues.push({
        type: "ABYSSAL_ALLEGIANCES_NO_WIZARD",
      });
      return;
    }

    if (hasWizard) {
      const min = 1;
      const max = this.abyssalAllegianceMaxCount;

      if (count < min || count > max)
        this.issues.push({
          type: "ABYSSAL_ALLEGIANCES_COUNT_OUT_OF_BOUNDS",
          count: count,
          min,
          max,
        });
    }
  }

  private validateCursedArtefacts() {
    const count = this.cursedArtefacts.length;
    const hasGeneral = this.hasKeyword("general");

    // No Cursed Artefacts allowed if no Wizard keyword is present
    if (!hasGeneral && count > 0) {
      this.issues.push({
        type: "CURSED_ARTEFACTS_NO_GENERAL",
      });
    }
  }

  private validateKeywords(this: Unit) {
    const constraints = new Map<Trait, number>([
      [Trait.WEAKNESS, 2],
      [Trait.FOOTPRINT, 1],
    ]);
    const keywords = groupBy(this.keywords, (keyword) =>
      keyword.reason === KeywordReasonType.PURCHASED_BY_PLAYER ?
        "purchased"
      : "rest",
    );
    const purchased = keywords.getOr("purchased", []);
    const assigned = keywords.getOr("rest", []);
    const counts = new Map<Trait, number>();

    let maxTraitCountIssue: TraitCountMaxExceeded | null = null;
    let duplicateKeywordIssue: DuplicateNonNumericalKeyword | null = null;

    for (const keyword of purchased) {
      // For every keyword trait...
      for (const trait of keyword.definition.traits) {
        // Note how many are currently added
        const currentCount = counts.get(trait) ?? 0;
        const nextCount = currentCount + 1;
        counts.set(trait, nextCount);

        // See if this trait has a max count constraint
        const constaint = constraints.get(trait) ?? Infinity;

        // If next count is higher than the constraint, mark
        // the keyword as invalid.
        if (nextCount > constaint) {
          if (maxTraitCountIssue) {
            maxTraitCountIssue.count = nextCount;
          } else {
            maxTraitCountIssue = {
              type: "TRAIT_COUNT_MAX_EXCEEDED",
              trait,
              max: constaint,
              count: nextCount,
            };
          }
        }
      }

      // Additionally, check whether a non-numerical keyword hasn't been
      // purchased even though it was also assigned by other means.
      if (!keyword.isNumerical()) {
        const assignedKeyword = assigned.find((k) => k.id === keyword.id);

        if (assignedKeyword) {
          if (duplicateKeywordIssue) {
            duplicateKeywordIssue.count++;
          } else {
            duplicateKeywordIssue = {
              type: "DUPLICATE_NON_NUMERICAL_KEYWORD",
              keyword,
              count: 2,
            };
          }
        }
      }

      const error = keyword.validate();
      if (error) {
        this.issues.push(error);
      }
    }

    if (maxTraitCountIssue) {
      this.issues.push(maxTraitCountIssue);
    }

    if (duplicateKeywordIssue) {
      this.issues.push(duplicateKeywordIssue);
    }
  }

  /**
   * Traverses through supplied keywords, applies all "add keywords" effects,
   * and collapeses numerical keywords.
   */
  private unpackKeywords(
    this: Unit,
    keywords: Keyword[],
    rules: Ruleset,
  ): void {
    for (const keyword of keywords) {
      this.addKeyword(keyword);

      for (const effect of keyword.definition.effects) {
        if (effect.type === "add_keywords") {
          this.unpackKeywords(
            effect.keywords.map((keywordRef) =>
              Keyword.fromKeywordRefData(this, keywordRef, keyword),
            ),
            rules,
          );
        }
      }
    }
  }

  /**
   * Adds a keyword to a unit's list of keywords. Collapses Numerical Keywords
   * into a single keyword entry.
   */
  private addKeyword(this: Unit, keyword: Keyword): void {
    const existingKeyword = this.getKeyword(keyword.id);

    if (existingKeyword) {
      if (existingKeyword.isNumerical() && keyword.isNumerical()) {
        existingKeyword.mergeNumericalKeywords(keyword);
      } else {
        this.keywords.push(keyword);
      }
    } else {
      this.keywords.push(keyword);
    }
  }

  private applyKeywordsCost(this: Unit) {
    for (const keyword of this.keywords) {
      if (keyword.isNumerical()) {
        for (const increment of keyword.increments) {
          if (increment.cost) {
            this.pointsModifiers.push({
              value: increment.cost * this.keywordCostMultiplier,
              reason: `Purchased ${increment.name}`,
            });
          }
        }
      } else {
        if (keyword.cost) {
          this.pointsModifiers.push({
            value: keyword.cost * this.keywordCostMultiplier,
            reason: `Purchased ${keyword.name}`,
          });
        }
      }
    }
  }

  private applyKeywordsModifiers(this: Unit) {
    const statToModifier = {
      strikes: "strikesModifiers",
      melee_strikes: "meleeStrikesModifiers",
      ranged_strikes: "rangedStrikesModifiers",
      courage: "courageModifiers",
      range: "rangeModifiers",
      speed: "speedModifiers",
      abyssal_allegiances_max_count: "abyssalAllegiancesMaxCountModifiers",
    } as const;

    for (const keyword of this.keywords) {
      for (const effect of keyword.definition.effects) {
        if (effect.type === "modify_stats") {
          for (const modifier of effect.modifiers) {
            if (modifier.stat === "footprint") {
              this.footprintModifiers.push({
                stat: modifier.stat,
                value: modifier.value,
                operation: modifier.operation,
                reason: `Modified by ${keyword.name}`,
              });
            } else {
              const value =
                (
                  keyword.isNumerical() &&
                  modifier.value === KeywordValue.DEFERRED
                ) ?
                  keyword.value
                : modifier.value;

              this[statToModifier[modifier.stat]].push({
                stat: modifier.stat,
                value: value,
                operation: modifier.operation,
                reason: `Modified by ${keyword.name}`,
              });
            }
          }
        } else if (effect.type === "modify_target_numbers") {
          if (effect.combat_type === "melee") {
            this.meleeTargetScoresModifiers.push({
              operation: effect.operation,
              value: effect.value,
            });
          } else if (effect.combat_type === "ranged") {
            this.rangedTargetScoresModifiers.push({
              operation: effect.operation,
              value: effect.value,
            });
          }
        }
      }
    }
  }

  private sortKeywords() {
    this.keywords.sort(sortByImportance);
  }

  get name() {
    return this.data.name || stringifyUnitType(this.type);
  }

  get hasName() {
    return !!this.data.name;
  }

  get points() {
    return this.pointsModifiers.reduce(
      (sum, modifer) => sum + modifer.value,
      0,
    );
  }

  get summary() {
    return `${this.points}pts, ${stringifyUnitType(this.type)}`;
  }

  get keywordsSummary() {
    return this.keywords
      .map((keyword) => {
        if (keyword.id === "general") {
          const artefacts =
            this.cursedArtefacts.length > 0 ?
              ` (${this.cursedArtefacts.map((artefact) => artefact.name)})`
            : "";

          return `${keyword.name}${artefacts}`;
        }

        if (keyword.id === "wizard") {
          const allegiances =
            this.abyssalAllegiances.length > 0 ?
              ` (${this.abyssalAllegiances
                .map((allegiance) => allegiance.name)
                .join(", ")})`
            : "";

          return `${keyword.name}${allegiances}`;
        }

        return keyword.name;
      })
      .join(", ");
  }

  get speed() {
    return reduceNumericalModifiers(this.speedModifiers);
  }

  get range() {
    return reduceNumericalModifiers(this.rangeModifiers);
  }

  get strikes() {
    return reduceNumericalModifiers(this.strikesModifiers);
  }

  get meleeStrikes() {
    return reduceNumericalModifiers([
      {
        stat: "melee_strikes",
        operation: "set",
        value: this.strikes,
      },
      ...this.meleeStrikesModifiers,
    ]);
  }

  get rangedStrikes() {
    return reduceNumericalModifiers([
      {
        stat: "ranged_strikes",
        operation: "set",
        value: Math.floor(this.strikes / 2),
      },
      ...this.rangedStrikesModifiers,
    ]);
  }

  get courage() {
    return reduceNumericalModifiers(this.courageModifiers);
  }

  get footprint() {
    return this.footprintModifiers[this.footprintModifiers.length - 1].value;
  }

  get abyssalAllegianceMaxCount() {
    if (!this.hasKeyword("wizard")) {
      return 0;
    }

    return reduceNumericalModifiers(this.abyssalAllegiancesMaxCountModifiers);
  }

  get sortRank() {
    if (this.hasKeyword("general")) {
      return 0;
    }

    if (this.hasKeyword("battle_standard")) {
      return 1;
    }

    if (this.hasKeyword("wizard")) {
      return 2;
    }

    const typeToRank: Record<UnitType, number> = {
      [UnitType.LIGHT_INFANTRY]: 3,
      [UnitType.RANGED_INFANTRY]: 4,
      [UnitType.HEAVY_INFANTRY]: 5,
      [UnitType.MONSTROUS_INFANTRY]: 6,
      [UnitType.LIGHT_CAVALRY]: 7,
      [UnitType.HEAVY_CAVALRY]: 8,
      [UnitType.WAR_WAGON]: 9,
      [UnitType.CHARIOT]: 10,
      [UnitType.MONSTER]: 11,
      [UnitType.BEASTS]: 12,
      [UnitType.ARTILLERY]: 13,
    };

    return typeToRank[this.type];
  }

  static sortByRank(unitA: Unit, unitB: Unit) {
    return unitA.sortRank - unitB.sortRank;
  }

  get targetScores() {
    return this.profile.targetScores.map((targetScore) => {
      return {
        vsType: targetScore.vsType,
        meleeMinScore: reduceTargetScoreModifier(
          this.meleeTargetScoresModifiers,
          targetScore.minScore,
        ),
        rangedMinScore: reduceTargetScoreModifier(
          this.rangedTargetScoresModifiers,
          targetScore.minScore,
        ),
      };
    });
  }

  hasTargetScoreModifiers(this: Unit) {
    return (
      this.meleeTargetScoresModifiers.length > 0 ||
      this.rangedTargetScoresModifiers.length > 0
    );
  }

  hasKeyword(this: Unit, id: string) {
    return this.keywords.some((k) => k.id === id);
  }

  hasReservedKeyword(this: Unit) {
    return this.hasKeyword("general") || this.hasKeyword("battle_standard");
  }

  getKeyword(this: Unit, id: string) {
    return this.keywords.find((k) => k.id === id);
  }

  isValid(this: Unit) {
    return this.issues.length === 0;
  }
}

function reduceTargetScoreModifier(
  modifiers: TargetScoresModifier[],
  initialValue: number,
) {
  const value = modifiers.reduce((sum, modifier) => {
    switch (modifier.operation) {
      case "decrement": {
        return sum - modifier.value;
      }

      default: {
        console.error(
          `Unsupported modifier operation type ${modifier.operation}.`,
        );

        return sum;
      }
    }
  }, initialValue);

  return Math.max(value, 2);
}

import { useDatabase } from "@/ui/database-provider";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { boolean, is, type } from "superstruct";
import { useLocation } from "wouter";
import { syncStorage } from "../sync-handler/sync-storage";
import { SessionContext, SessionContextValue } from "./context";
import { ory, oryErrorStruct } from "./ory";
import { Pending, Success } from "@/lib/async-op";

function useExperimentalFeatures(
  sessionOp: SessionContextValue["sessionResult"],
) {
  useEffect(() => {
    if (
      // A user session is active
      sessionOp.isSuccess &&
      sessionOp.value !== null &&
      // Session identity includes public metadata with experimental scrollbars
      is(
        sessionOp.value.identity,
        type({ metadata_public: type({ experimental_scrollbars: boolean() }) }),
      ) &&
      // Experimental scrollbars are enabled.
      sessionOp.value.identity.metadata_public.experimental_scrollbars
    ) {
      document.body.classList.add("experimental_scrollbars");
    }

    return () => {
      document.body.classList.remove("experimental_scrollbars");
    };
  }, [sessionOp]);
}

async function doesSessionExist() {
  try {
    const resp = await fetch("/api/auth/session");
    return resp.ok ? boolean().create(await resp.json()) : false;
  } catch {
    return false;
  }
}

export function SessionProvider(props: { children: ReactNode }) {
  const db = useDatabase();
  const [_, setLocation] = useLocation();
  const [sessionOp, setSessionOp] = useState<
    SessionContextValue["sessionResult"]
  >(new Pending());

  useExperimentalFeatures(sessionOp);

  useEffect(() => {
    async function getOrySession() {
      try {
        // First, let's check with the server whether an ory session cookie
        // exists. This has to be via server request, because the cookie
        // is a HttpOnly cookie — we cannot access it from JS for security
        // reasons.
        const sessionExists = await doesSessionExist();

        // If session exists, it means that the user has either just logged
        // in after being redirected from the auth server (and they have never
        // synced on this device before), or they have already been using
        // this device. Either way, we can safely get the session info from
        // Ory, enable sync, and extend the session.
        if (sessionExists) {
          const { data: session } = await ory.toSession();
          setSessionOp(new Success(session));

          // This might be already set to true, but in case this is the first
          // login case, we need to set it to true.
          syncStorage.isSyncEnabled = true;

          // Extend session for logged in user
          await fetch("/api/extend", { method: "POST" });
        } else {
          // If session doesn't exist but sync has been enabled, it means that
          // the user enabled sync on this device, but the session has expired.
          // We need to get them to re-authenticate.
          if (syncStorage.isSyncEnabled) {
            setLocation("/unauthenticated", { replace: true });
          }

          // If there is no session and sync hasn't been enabled the user is using
          // the app in local-only mode.
          setSessionOp(new Success(null));
        }
      } catch (error) {
        // We got an error whilst sync has been enabled. This means credentials
        // have probably expired. We need to get the user to reauthenticate or
        // log out.
        if (
          oryErrorStruct.is(error) &&
          error.response.data.error.code === 401 &&
          syncStorage.isSyncEnabled
        ) {
          setLocation("/unauthenticated", { replace: true });
        }

        // In case there are other errors we re-throw them to log them to Sentry.
        throw error;
      }
    }

    void getOrySession();
  }, [setLocation]);

  const context: SessionContextValue = useMemo(() => {
    return {
      sessionResult: sessionOp,
      session: sessionOp.valueOrNull(),
      isLoggedIn: sessionOp.hasTruthyValue(),
      isRetrievingSession: sessionOp.isPending,
      logOut: async () => {
        // End User Session in Ory
        try {
          const { data: flow } = await ory.createBrowserLogoutFlow();
          await ory.updateLogoutFlow({ token: flow.logout_token });
        } catch (error) {
          if (
            oryErrorStruct.is(error) &&
            error.response.data.error.code === 401
          ) {
            console.info("Session already ended in Ory.");
          } else {
            throw error;
          }
        }

        // Mark user as logged out
        setSessionOp(new Success(null));

        // Delete all local data
        await db.clearAll();
        syncStorage.lastSuccessfulSyncTs = null;
        syncStorage.syncLog = [];
        syncStorage.isSyncEnabled = false;

        // Redirect to home
        setLocation("/");
      },
    };
  }, [db, sessionOp, setLocation]);

  return (
    <SessionContext.Provider value={context}>
      {props.children}
    </SessionContext.Provider>
  );
}

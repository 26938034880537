import { PreorderButton } from "@/ui/preorder-button";
import { Footer } from "@/ui/footer";
import { Image } from "@/ui/image";
import { Link } from "@/ui/link";
import * as css from "./style.css";

function ToolCard(props: {
  name: string;
  description: string;
  href: string;
  beta?: boolean;
}) {
  return (
    <Link href={props.href} className={css.toolLink}>
      {props.beta && <span className={css.betaLabel}>Beta</span>}
      <h2 className={css.toolHeading}>{props.name}</h2>
      <p className={css.toolDescription}>{props.description}</p>
    </Link>
  );
}

export function RootPage() {
  return (
    <main className={css.main}>
      <div className={css.content}>
        <Image
          src="/logos/logo.png"
          alt="Hobgoblin"
          className={css.logo}
          width={4188}
          height={3221}
        />

        <div className={css.tools}>
          <ToolCard
            name="Army Builder"
            description="Build, manage, and share your Hobgoblin armies!"
            href="/army"
          />

          <ToolCard
            name="Chaos of War"
            description="Generate scenario, battlefield, deployment, and twist with one click!"
            href="/battle"
          />

          <ToolCard
            name="Fortune Cards"
            description="Use a regular deck of playing cards as your Fortune deck."
            href="/fortune"
            beta
          />

          <PreorderButton />
        </div>

        <footer className={css.footer}>
          <Footer />
        </footer>
      </div>

      <div className={css.cover} />
    </main>
  );
}

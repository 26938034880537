import { Body } from "@/ui/body";
import { FormDialog } from "@/ui/form-dialog";
import { useRuleset } from "@/ui/game-content-provider";
import { Checkbox } from "@/ui/inputs/checkbox";
import { DialogStore } from "@ariakit/react";
import { useState } from "react";
import * as css from "./style.css";
import { Unit } from "@/domain/unit";

export function CursedArtefactsDialog(props: {
  unit: Unit;
  onSubmit: (artefactId: string | null) => Promise<void>;
  store: DialogStore;
}) {
  const ruleset = useRuleset();
  const [artefactId, setArtefactId] = useState<string | null>(
    props.unit.cursedArtefacts[0]?.id ?? null,
  );

  return (
    <FormDialog
      heading="Cursed Artefacts"
      subheading="Your General may select one of the following Cursed Artefacts, granting them the associated strengths and weaknesses."
      submit="Done"
      store={props.store}
      onSubmit={async () => props.onSubmit(artefactId)}
    >
      {ruleset.artefacts.map((artefact) => {
        return (
          <div key={artefact.id} className={css.artefact}>
            <h2 className={css.artefactName}>
              <Checkbox
                label={artefact.name}
                name={artefact.id}
                checked={artefact.id === artefactId}
                onChecked={() => setArtefactId(artefact.id)}
                onUnchecked={() => setArtefactId(null)}
              />
            </h2>

            {artefact.keywordsDefs.map((keywordDef) => {
              return (
                <div className={css.keyword} key={keywordDef.id}>
                  <h3 className={css.keywordName}>{keywordDef.name}</h3>
                  <Body
                    key={keywordDef.id}
                    html={keywordDef.descriptionWithTraits}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </FormDialog>
  );
}

import { config } from "@/config";
import * as css from "./styles.css";

function stringifyDetails(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === "string") {
    return error;
  } else {
    return "Error could not be stringified.";
  }
}

export function ErrorMessage(props: {
  message: string;
  details?: unknown;
  code?: string;
}) {
  return (
    <div className={css.container}>
      <div className={css.message}>{props.message}</div>

      {props.code && <div className={css.code}>Error: {props.code}</div>}

      {config.EXPOSE_ERRORS && !!props.details && (
        <div
          style={{
            marginTop: "1rem",
            fontFamily: "monospace",
            padding: "1rem",
            border: "1px solid black",
            textAlign: "left",
          }}
        >
          <strong>Error:</strong> {stringifyDetails(props.details)}
        </div>
      )}
    </div>
  );
}

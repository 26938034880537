import { UserSettingsPage } from "@/ui/settings-page";
import { BattlePage } from "@/battle/battle-page";
import { Redirect, Route, Switch } from "wouter";
import { AccountView } from "../account-view";
import { ArmyBuilderPages } from "../army-pages";
import { FortunePage } from "../fortune/fortune-page";
import { FortunesProvider } from "../fortune/fortunes-context";
import { LocalArmyProvider } from "../local-army-provider";
import { PrintArmyPage } from "../print-army-page";

function RedirectToUserSettings() {
  return <Redirect to="/settings" replace />;
}

function RedirectToFortuneHelp() {
  return <Redirect to="/help/fortune" replace />;
}

function BattlePages() {
  return (
    <Switch>
      <Route path="/battle" component={BattlePage} />
      <Route path="/battle/settings" component={RedirectToUserSettings} />
    </Switch>
  );
}

function FortunePages() {
  return (
    <FortunesProvider>
      <Switch>
        <Route path="/fortune" component={FortunePage} />
        <Route path="/fortune/help" component={RedirectToFortuneHelp} />
      </Switch>
    </FortunesProvider>
  );
}

export function ToolsPages() {
  return (
    <Switch>
      <Route path="/army/:rest*" component={ArmyBuilderPages} />
      <Route path="/battle/:rest*" component={BattlePages} />
      <Route path="/fortune/:rest*" component={FortunePages} />
      <Route path="/print/:rest*" component={PrintPages} />
      <Route path="/settings" component={UserSettingsPage} />
      <Route path="/account" component={AccountView} />
    </Switch>
  );
}

function PrintPages() {
  return (
    <Switch>
      <Route path="/print/a/:armyId" component={PrintArmyPageRoute} />
    </Switch>
  );
}

function PrintArmyPageRoute(props: { params: { armyId: string } }) {
  return (
    <LocalArmyProvider armyId={props.params.armyId}>
      <PrintArmyPage />
    </LocalArmyProvider>
  );
}



import { HobgoblinDatabase } from "@/lib/db";
import { Pending } from "@/lib/async-op";
import { FortuneData } from "@/schema/latest";
import { useQuery } from "@/ui/database-provider/hooks";
import { QueryOp } from "@/ui/database-provider/types";
import { useRuleset } from "@/ui/game-content-provider";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";

export type UsedFortune = {
  name: string;
  description: string;
  cardId: string;
  persisted?: boolean;
};

export type AddCard = (fortune: UsedFortune) => void;
export type RemoveCard = (cardId: string) => void;

const FortunesContext = createContext<QueryOp<FortuneData>>(new Pending());

export const useFortuneCards = () => useContext(FortunesContext);

function queryLatestFortune(fallbackVersion: string) {
  return (db: HobgoblinDatabase) => {
    return db.getFortuneHand(fallbackVersion);
  };
}

export function FortunesProvider(props: { children: ReactNode }) {
  const ruleset = useRuleset();
  const result = useQuery(queryLatestFortune, ruleset.version);

  return (
    <FortunesContext.Provider value={result}>
      {props.children}
    </FortunesContext.Provider>
  );
}

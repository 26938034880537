import { KeywordRefData, NumericalQueryData } from "../schema/latest.js";
import { KeywordReasonType } from "./enums.js";
import { Footprint } from "./footprint.js";
import {
  NumericalKeywordType,
  assertNumericalKeywordType,
} from "./numerical-keyword-type.js";

type AddedByKeywordReason = {
  type: KeywordReasonType.ADDED_BY_KEYWORD;
  keywordName: string;
};

type AddedByCursedArtefactReason = {
  type: KeywordReasonType.ADDED_BY_CURSED_ARTEFACT;
  cursedArtefactName: string;
};

type PurchasedByPlayerReason = {
  type: KeywordReasonType.PURCHASED_BY_PLAYER;
  cost: number;
};

type AssignedByPlayerReason = {
  type: KeywordReasonType.ASSIGNED_BY_PLAYER;
};

type DefaultKeywordReason = {
  type: KeywordReasonType.DEFAULT_KEYWORD;
};

export type KeywordReason =
  | AddedByCursedArtefactReason
  | AddedByKeywordReason
  | AssignedByPlayerReason
  | DefaultKeywordReason
  | PurchasedByPlayerReason;

export enum KeywordValue {
  DEFERRED = "KEYWORD_VALUE",
}

export type NumericalStatModifier = {
  stat:
    | "strikes"
    | "melee_strikes"
    | "ranged_strikes"
    | "courage"
    | "range"
    | "speed"
    | "abyssal_allegiances_max_count";

  value: KeywordValue.DEFERRED | number;
  operation: "increment" | "decrement" | "set" | "divide";
  reason?: string;
};

export type FootprintStatModifier = {
  stat: "footprint";
  value: Footprint;
  operation: "set";
  reason?: string;
};

export type TargetScoresModifier = {
  value: number;
  operation: "decrement";
};

export type StatModifier = NumericalStatModifier | FootprintStatModifier;

export enum KeywordType {
  KEYWORD = "KEYWORD",
  NUMERICAL_KEYWORD = "NUMERICAL_KEYWORD",
}

export type ModifyStatEffect = {
  type: "modify_stats";
  modifiers: StatModifier[];
};

export type ModifyTargetNumbersEffect = {
  type: "modify_target_numbers";
  combat_type: "melee" | "ranged";
  value: number;
  operation: "decrement";
};

export type AddKeywordEffect = {
  type: "add_keywords";
  keywords: KeywordRef[];
};

export type KeywordEffect =
  | ModifyStatEffect
  | AddKeywordEffect
  | ModifyTargetNumbersEffect;

export type KeywordPurchaseOption = {
  cost: number;
  value?: number;
};

export type NumericalQuery = {
  value: number;
  type: NumericalKeywordType;
};

export function toNumericalQuery({
  value,
  type,
}: NumericalQueryData): NumericalQuery {
  assertNumericalKeywordType(type);
  return { value, type };
}

export type KeywordRef = {
  id: string;
  numerical?: NumericalQuery;
};

export function toKeywordRef(data: KeywordRefData): KeywordRef {
  return {
    ...data,
    numerical: data.numerical && toNumericalQuery(data.numerical),
  };
}

export function replaceKeywordValue(s: string, value: string | number) {
  return s.replaceAll(/\bx\b/g, `${value}`);
}

export const RESERVED_KEYWORDS_IDS = ["general", "battle_standard"];

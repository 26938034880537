import { Unit } from "@/domain/unit";
import { ImmutableQueue } from "@/lib/queue";
import { Body } from "@/ui/body";
import { FormDialog } from "@/ui/form-dialog";
import { useRuleset } from "@/ui/game-content-provider";
import { Checkbox } from "@/ui/inputs/checkbox";
import { DialogStore } from "@ariakit/react";
import { useState } from "react";
import * as css from "./style.css";

export function AbyssalAllegiancesDialog(props: {
  unit: Unit;
  onSubmit: (abyssalAllegiancesIds: string[]) => Promise<void>;
  store: DialogStore;
}) {
  const ruleset = useRuleset();
  const [selectedIds, setSelectedIds] = useState(
    new ImmutableQueue(
      props.unit.abyssalAllegiances.map((a) => a.id),
      props.unit.abyssalAllegianceMaxCount === 1 ? 1 : Infinity,
    ),
  );

  return (
    <FormDialog
      heading="Abyssal Allegiances"
      subheading={
        props.unit.abyssalAllegianceMaxCount > 1 ?
          `A Wizard must select 1–${props.unit.abyssalAllegianceMaxCount} of the following Abyssal Allegiances.`
        : `A Wizard must select one of the following Abyssal Allegiances.`
      }
      submit="Done"
      store={props.store}
      onSubmit={async () => props.onSubmit(Array.from(selectedIds).sort())}
    >
      {ruleset.abyssalAllegiances.map((allegiance) => {
        return (
          <div key={allegiance.id} className={css.allegiance}>
            <h2 className={css.allegianceName}>
              <Checkbox
                label={allegiance.name}
                name={allegiance.id}
                checked={selectedIds.includes(allegiance.id)}
                onChecked={() => {
                  setSelectedIds(selectedIds.shift(allegiance.id));
                }}
                onUnchecked={() => {
                  setSelectedIds(selectedIds.delete(allegiance.id));
                }}
              />
            </h2>

            {allegiance.spells.map((spell) => {
              return (
                <div className={css.spell} key={spell.name}>
                  <h3 className={css.spellName}>{spell.name}</h3>
                  <Body html={spell.descriptionWithType} />
                </div>
              );
            })}
          </div>
        );
      })}
    </FormDialog>
  );
}

import { CSSProperties } from "react";

export function Image(props: {
  src: string;
  alt?: string;
  width: number;
  height: number;
  className?: string;
  style?: CSSProperties;
}) {
  return <img {...props} />;
}

import { UnitType } from "@/domain/unit-type";
import { stringifyUnitType } from "@/domain/units";
import { FormDialog } from "@/ui/form-dialog";
import { useRuleset } from "@/ui/game-content-provider";
import { useField } from "@/ui/hooks/use-field";
import { RadioGroupField, TextAreaField, TextField } from "@/ui/inputs";
import { InputsGroup } from "@/ui/inputs/group";
import { DialogStore } from "@ariakit/react";
import { useState } from "react";

type UnitFormValues = {
  name: string;
  description: string;
  type: UnitType;
};

export function UnitFormDialog(props: {
  heading: string;
  submit: string;
  initialValues?: Partial<UnitFormValues>;
  onSubmit: (values: UnitFormValues) => Promise<void>;
  store: DialogStore;
}) {
  const ruleset = useRuleset();
  const [nameField, name] = useField(props.initialValues?.name);
  const [descField, description] = useField(props.initialValues?.description);
  const [type, setType] = useState(
    props.initialValues?.type ?? UnitType.LIGHT_INFANTRY,
  );

  return (
    <FormDialog
      heading={props.heading}
      submit={props.submit}
      store={props.store}
      onSubmit={() => props.onSubmit({ name, description, type })}
    >
      <InputsGroup>
        <TextField {...nameField} label="Name" placeholder="Eg. Darkswords" />

        <TextAreaField
          {...descField}
          label="Description"
          placeholder="Optionally, add a description for this unit."
        />

        <RadioGroupField
          key="type"
          label="Type"
          options={ruleset.unitProfiles.map((profile) => {
            return {
              label: stringifyUnitType(profile.type),
              value: profile.type,
              checked: profile.type === type,
              extra: `${profile.points}pts`,
              onChange: () => setType(profile.type),
            };
          })}
        />
      </InputsGroup>
    </FormDialog>
  );
}

import { randomItem } from "./random";

const data = [
  // Official wild locations (Oorm, Ghobs, etc...)
  {
    locations: [
      "Shardharth",
      "Solkag",
      "Llandia",
      "Caisleach",
      "Paraima",
      "Naleb",
      "Xog",
      "Shaam",
      // "The Drakhal",
      // "Lucreta",
      "Ngubz",
      "Zroksetek",
      "Goblin Warrens",
      "Drotsmak",
      "Tsamsak",
      "Negbil",
      "Zedlog",
      "Sciria",
      "Joldown",
      "The Mushroom Jungles",
      "Lherzwell",
      "The Frozen Catacombs",
      "Eldhål",
      "The Iron Lake",
      "Zagrad",
      "Ormstrup",
      "Nolor",
      "Cathairban",
      "Deepsop",
      "Kaltmintbe",
      "Tobarvein",
      "Noelada",
      "Uderfel",
      "Golgamol",
      "Gherz",
      "Teekna",
      "Valkrok",
      "K'oopsb",
      "Zaasta",
      "Mica",
      "Bruxa",
      "Bogtuxla",
      "Keng-pit",
      "Tarnalt",
      "Dacz",
      "Xalbi",
      "Hostok",
      "Methaldark",
      "Ragbog",
      "Bragherd",
      "Ralbarg",
      "Zriskak",
      "Mossav",
      "Snodtred",
      "Krazag",
      "Low Kanada",
      "Properanius",
      "Lucretanica",
      "Trondhjem",
      "Maelion",
      "Basaltar",
      "Motzag",
      "Lherz",
      "Teluje Mis",
      "Zaalsh",
      "Zlemor",
      "Vorm",
      "Tsemek",
      "Tisuut",
      "Narzah",
      "Swamp Caves",
      "Casvipes Abyss",
      "Breuk",
      "Moksak",
      "Breuk",
      "Moksak",
      "Enbor",
      "Ziben",
      "Aerahem",
      "Nulduch",
      // "Sea Of Pearls",
      "Keng-pit",
      "Gogli",
      "Spine Gulch",
      "Slograk",
    ],
    organization: [
      "Warband",
      "Wartribe",
      "Tribe",
      "Nomads",
      "Horde",
      "Khanate",
      "Chiefdom",
      "Outrealm",
      "Expedition",
      "Raiding Party",
      "Outriders",
    ],
  },

  // Official civilised locations (Elves, Dwarvers, Humans, etc...)
  {
    locations: [
      "The Frozen Steps",
      "Ice Mines",
      "The Sempiternal Labyrinth",
      "Trottborn Gate",
      "The Miraged City",
      "The Blacklight Sea",
      "The Marble Forest",
      "The Vakti",
      "The Endless Nest",
      "Cidereach",
      "Bleakwater Fastness",
      "The Last Garden",
      "The White City",
      "Greensand",
      "Grandel",
      "Nortz",
      "Swamp Caves",
      "The Kobi Basin",
      "River Gursh",
      "The Vents",
      "Litchfel Hold",
      "Blessed Below",
      "Beaconsdown",
      "Marketdown",
      "Shalemead",
      "Dimgrave",
      "Bleakwater",
      "Rockmead",
      "Brunsvault",
      "Coedia",
      "Eir",
      "Dinshard",
      "Warsell",
      "Eldvarlden",
      "Noelada",
      "Varsgate",
      "Stenwyrm Eyries",
      "Wyrmhold",
      "Vorzsbak",
      "Ogalganon",
      "Khogbol",
      "Kartsar",
      "Keedzb",
      "Taatchchaapyeegsh",
      "Doombil",
      "Sarnac",
      "Smezmol",
      "Gitbog",
      "Zbuuk",
      "Gaddok",
      "Devilforge",
      "Lantenwell",
      "Snartzhold",
      "Marlstown",
      "Lampwell",
      "Gosswater",
      "Hornblen",
      "Limewater",
      "Mugstone",
      "Brogbol",
      "Shrewcave",
      "Haltergate",
      "Lorem Ipsum",
      "Dunstone",
      "Midgarden",
      "Lizzadrel",
      "Masonwell",
      "Feldspar",
    ],
    organization: [
      "Army",
      "Garrison",
      "Warband",
      "Tribe",
      "Guard",
      "Legion",
      "Kingdom",
      "Duchy",
      "Fiefdom",
      "Barony",
      "Earldom",
      "Lordship",
      "City-state",
    ],
  },

  // Generic locations
  {
    locations: [
      "Grizzlemaw",
      "Shadowcrypt",
      "Abyssal Gorge",
      "Voidspire",
      "Obsidian Citadel",
      "Bloodstone",
      "Murkwood",
      "Echoing Abyss",
      "Gloomshroud",
      "Dreadforge",
      "Ashenfalls",
      "Soulflame",
      "Cursed Labyrinth",
      "Bonechill",
      "Ebonshard",
      "Wraithhold",
      "Blackthorn",
      "Veilstitch",
      "Sablethorn",
      "Ironvein Enclave",
      "Ruinshroud",
      "Nightshade",
      "Sorrowstone",
      "Forsaken Spire",
      "Voidborne",
      "Darkwater",
      "Gloomspire",
      "Felfire",
      "Shadecraft",
      "Dreadhollow",
      "Emberfall Abyss",
      "Grimfrost Citadel",
      "Ashenblood",
      "Obsidian Watch",
      "Bloodshadow",
      "Deathforge",
      "Umbral Rift",
      "Thundershade",
      "Wraithbarrow",
      "Emberfall Enclave",
      "Netherstone",
      "Mournhold",
      "Vilecrystal",
    ],
    organization: [
      "Kingdom",
      "Duchy",
      "Fiefdom",
      "Barony",
      "Earldom",
      "Lordship",
      "Emirate",
      "Sultanate",
      "Caliphate",
      "Grand Duchy",
      "Palatinate",
      "Margraviate",
      "Khanate",
      "Chiefdom",
      "League",
      "Canton",
      "Dominion",
      "Electorate",
      "Federation",
      "Hegemony",
      "Kraal",
      "Outrealm",
      "Praetorate",
      "Syndicate",
      "Thalassocracy",
      "Vassal State",
      "Expedition",
      "Tribe",
      "City-state",
      "Raiding Party",
      "Warband",
    ],
  },
];

export function getRandomArmyName() {
  const dataset = randomItem(data);

  if (Math.random() > 0.5) {
    return `${randomItem(dataset.locations)} ${randomItem(
      dataset.organization,
    )}`;
  }

  return `${randomItem(dataset.organization)} of ${randomItem(
    dataset.locations,
  )}`;
}

import checkmark from "@/images/checkmark.svg";
import { Image } from "@/ui/image";
import { ReactNode } from "react";
import * as css from "./style.css";

export function Checkbox(props: {
  label: ReactNode;
  name: string;
  checked: boolean;
  disabled?: boolean;
  onChecked: (name: string) => void;
  onUnchecked: (name: string) => void;
}) {
  const opacity = props.disabled ? "0.3" : "1";

  return (
    <label className={css.checkbox}>
      <span className={css.checkboxContainer} style={{ opacity }}>
        <input
          className={css.checkboxInput}
          type="checkbox"
          name={props.name}
          disabled={props.disabled}
          checked={props.checked}
          onChange={(event) => {
            if (event.target.checked) {
              props.onChecked(props.name);
            } else {
              props.onUnchecked(props.name);
            }
          }}
        />
        {props.checked && (
          <Image
            src={checkmark}
            alt=""
            className={css.checkboxMark}
            width={36}
            height={36}
          />
        )}
      </span>

      <span className={css.inputLabel} style={{ opacity }}>
        {props.label}
      </span>
    </label>
  );
}

import { GameSystem } from "@/lib/game-system";
import { Pending } from "@/lib/async-op";
import { isNullish } from "@/lib/typeguards";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useSyncExternalStore,
} from "react";
import { Ruleset } from "../../domain/ruleset";
import latest from "../../rulesets/v1.0.0.json";

const game = new GameSystem([latest]);

const GameContentLookupContext = createContext<GameSystem>(game);

// Pending state with stable identity — necessary so that React's useSyncExternalStore
// doesn't enter an infinite loop.
const PENDING = new Pending();

export function useResolveVersion(version?: string | null) {
  const subscribe = useCallback((callback: () => void) => {
    game.subscribe(callback);

    return () => {
      game.unsubscribe(callback);
    };
  }, []);

  const getSnapshot = useCallback(() => {
    if (!isNullish(version)) {
      return game.resolve(version);
    }

    return PENDING;
  }, [version]);

  return useSyncExternalStore(subscribe, getSnapshot);
}

export function useGameContentLookup() {
  return useContext(GameContentLookupContext);
}

export function GameContentLookupProvider(props: { children: ReactNode }) {
  return (
    <GameContentLookupContext.Provider value={game}>
      {props.children}
    </GameContentLookupContext.Provider>
  );
}

export const RulesetContext = createContext<Ruleset>(game.latestRuleset);

export function useRuleset() {
  return useContext(RulesetContext);
}

export function RulesetProvider(props: {
  children: ReactNode;
  ruleset: Ruleset;
}) {
  return (
    <RulesetContext.Provider value={props.ruleset}>
      {props.children}
    </RulesetContext.Provider>
  );
}

/**
 * Functional alternative to a switch/case statement.
 *
 * Unlike a switch statement, `pick` makes sure that all options are
 * covered.
 *
 * Also unlike a switch statement, a pick call actually evaluates all its
 * option (branches). Don't use it for expensive operations or anything
 * that has side-effects.
 *
 * @example
 * ```ts
 * const cardId: "AH" | "AS" | "AC" | "AD" = "AH";
 *
 * const cardName = pick(cardId, {
 *   "AH": "Ace of Hearts",
 *   "AS": "Ace of Spades",
 *   "AC", "Ace of Clubs",
 *   "AD", "Ace of Diamonds",
 * })
 * ```
 */

export const pick = <T, U extends keyof T>(key: U, record: T) => {
  return record[key];
};

/**
 * Functional alternative to `test ? value : undefined`
 *
 * Slightly more ergonomic and intentional than the above mentioned ternary.
 * Mostly used in style objects, when a value should be present only if a
 * certain prop is defined.
 */

export const when = <T>(test: unknown, value: T) => {
  if (test) {
    return value;
  }

  return undefined;
};

import { KeywordRefData } from "../schema/latest.js";
import { KeywordRef, toKeywordRef } from "./keyword-types.js";
import { Ruleset } from "./ruleset.js";
import { Unit } from "./unit.js";

export type CursedArtefactRef = {
  id: string;
};

export type CursedArtefactDefinitionData = {
  id: string;
  name: string;
  keywords: KeywordRefData[];
};

export class CursedArtefactDefinition {
  ruleset: Ruleset;
  id: string;
  name: string;
  keywordsRefs: KeywordRef[];

  constructor(ruleset: Ruleset, data: CursedArtefactDefinitionData) {
    this.ruleset = ruleset;
    this.id = data.id;
    this.name = data.name;
    this.keywordsRefs = data.keywords.map((data) => {
      return toKeywordRef(data);
    });
  }

  get keywordsDefs() {
    return this.keywordsRefs.map((keywordRef) => {
      return this.ruleset.keywordsDefsById.getOrThrow(keywordRef.id);
    });
  }
}

export class CursedArtefact {
  id: string;
  name: string;
  unit: Unit;
  definition: CursedArtefactDefinition;

  constructor(unit: Unit, def: CursedArtefactDefinition) {
    this.id = def.id;
    this.name = def.name;
    this.unit = unit;
    this.definition = def;
  }

  get keywordsSummary() {
    return this.definition.keywordsDefs.map((k) => k.name).join(", ");
  }

  static fromRef(unit: Unit, ref: CursedArtefactRef) {
    return new CursedArtefact(
      unit,
      unit.ruleset.artefactsById.getOrThrow(ref.id),
    );
  }
}

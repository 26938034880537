import { useDatabase } from "@/ui/database-provider";
import { Button } from "@/ui/button";
import { useDialog } from "@/ui/hooks/use-dialog";
import { useRouter } from "@/ui/hooks/use-router";
import { button, primaryTheme } from "@/ui/utils.css";
import { CreateArmyDialog } from "../army-form-dialog";

export function CreateArmyButton(props: {
  theme?: Record<string, string>;
  rulesetVersion: string;
}) {
  const setLocation = useRouter();
  const database = useDatabase();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <CreateArmyDialog
          store={store}
          onSubmit={async (values) => {
            const id = await database.createArmy({
              ...values,
              ruleset_version: props.rulesetVersion,
            });
            setLocation(`/army/a/${id}`);
          }}
        />
      )}

      <Button
        onClick={setOpen}
        className={button}
        style={props.theme ?? primaryTheme}
      >
        Create Army
      </Button>
    </>
  );
}

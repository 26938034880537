import { Image } from "@/ui/image";
import * as css from "./style.css";
import { ReactNode } from "react";

type Issue =
  | {
      message: ReactNode;
      iconSrc?: string;
    }
  | string;

export function IssuesList(props: { issues: Issue[] }) {
  return (
    <div className={css.issues}>
      {props.issues.map((issueOrMessage, index) => {
        const issue =
          typeof issueOrMessage === "string"
            ? { message: issueOrMessage }
            : issueOrMessage;

        return (
          <div className={css.issue} key={index}>
            <Image
              src={issue.iconSrc ?? "/icons/skull.svg"}
              width={40}
              height={40}
              className={css.issueIcon}
            />

            <div>{issue.message}</div>
          </div>
        );
      })}
    </div>
  );
}

import { parseSemver } from "./semver.js";
import { isNullish } from "./typeguards.js";

// We could use Array#findLast, but Vercel's version of TypeScript keeps warning
// us that it's not a valid method.
function findLast<T>(array: T[], isMatch: (item: T) => boolean) {
  let lastFound: T | null = null;
  for (const item of array) {
    if (isMatch(item)) {
      lastFound = item;
    }
  }
  return lastFound;
}

class SemverSegment<T> {
  private valuesByVersion: Map<number, T>;

  constructor(entries: Array<[number, T]> = []) {
    this.valuesByVersion = new Map(entries);
  }

  get latest() {
    const version = this.versions.at(-1);
    if (!isNullish(version)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.valuesByVersion.get(version)!;
    }

    return null;
  }

  get versions() {
    return Array.from(this.valuesByVersion.keys()).sort();
  }

  has(version: number) {
    return this.valuesByVersion.has(version);
  }

  get(version: number) {
    return this.valuesByVersion.get(version);
  }

  resolve(minVersion: number) {
    const version = findLast(this.versions, (v) => v >= minVersion);

    if (!isNullish(version)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.valuesByVersion.get(version)!;
    }

    return null;
  }

  set(version: number, value: T) {
    this.valuesByVersion.set(version, value);

    return this;
  }
}

type MajorSegment<T> = SemverSegment<MinorSegment<T>>;

type MinorSegment<T> = SemverSegment<PatchSegment<T>>;

type PatchSegment<T> = SemverSegment<T>;

export class SemverResolver<T> {
  private majors: MajorSegment<T>;

  constructor(entries: ReadonlyArray<readonly [string, T]>) {
    this.majors = new SemverSegment();
    for (const [version, value] of entries) {
      this.add(version, value);
    }
  }

  get latest() {
    return this.majors.latest?.latest?.latest ?? null;
  }

  resolve(requestedVersion: string) {
    const semver = parseSemver(requestedVersion);
    const isStable = semver.major >= 1;

    const major = this.majors.get(semver.major);
    if (!major) {
      return null;
    }

    const minorVersion =
      isStable ? major.resolve(semver.minor) : major.get(semver.minor);

    if (!minorVersion) {
      return null;
    }

    return minorVersion.resolve(semver.patch);
  }

  add(version: string, value: T) {
    const semver = parseSemver(version);

    if (!this.majors.has(semver.major)) {
      this.majors.set(semver.major, new SemverSegment());
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const major = this.majors.get(semver.major)!;

    if (!major.has(semver.minor)) {
      major.set(semver.minor, new SemverSegment());
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const minor = major.get(semver.minor)!;

    minor.set(semver.patch, value);
  }
}

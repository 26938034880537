export type ButtonProps = JSX.IntrinsicElements["button"]

/**
 * A regular HTML button, but set to type="button" by default (can be overriden).
 *
 * This avoids the classic form submission foot-gun, or submitting a form with
 * the wrong buttons.
 */
export function Button(props: ButtonProps) {
  return <button type="button" {...props} />;
}

import { ArmiesPage } from "@/ui/armies-page";
import { ArmyDetailPage } from "@/ui/army-detail-page";
import { ArmyProvider } from "@/ui/army-provider";
import {
  SharedArmyDetailPage,
  useFetchSharedArmy,
} from "@/ui/shared-army-detail-page";
import { Route, Switch } from "wouter";
import { LocalArmyProvider } from "../local-army-provider";

export function ArmyBuilderPages() {
  return (
    <Switch>
      <Route path="/army" component={ArmiesPage} />
      <Route path="/army/a/:armyId/:rest*" component={ArmyPages} />
      <Route path="/army/s/:shareId/:rest*" component={SharedArmyPages} />
    </Switch>
  );
}

export function ArmyPages(props: { params: { armyId: string } }) {
  return (
    <LocalArmyProvider armyId={props.params.armyId}>
      <ArmyDetailPage />
    </LocalArmyProvider>
  );
}

export function SharedArmyPages(props: { params: { shareId: string } }) {
  const result = useFetchSharedArmy(props.params.shareId);

  return (
    <ArmyProvider armyDataOp={result}>
      <SharedArmyDetailPage {...props} />
    </ArmyProvider>
  );
}

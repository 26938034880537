import arrowLeft from "@/images/arrow-left.svg";
import menu from "@/images/menu.svg";
import { useDialog } from "@/ui//hooks/use-dialog";
import { usePathname } from "@/ui//hooks/use-pathname";
import { MinWidth } from "@/ui//media";
import { ModalDialog } from "@/ui//modal-dialog";
import { VersionIndicator } from "@/ui//version-indicator";
import { Image } from "@/ui/image";
import { Link } from "@/ui/link";
import { NavLinks } from "@/ui/nav-links";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  type ReactNode,
} from "react";
import { PreorderButton } from "../preorder-button";
import * as css from "./style.css";

function SideNav(props: { children: ReactNode }) {
  return (
    <div className={css.sideNav}>
      <div className={css.logoSection}>
        <Link href="/" className={css.logoLink}>
          <Image
            src="/logos/logo-medium.png"
            alt="Hobgoblin"
            className={css.logo}
            width={4000}
            height={2940}
          />
        </Link>

        <VersionIndicator />
      </div>

      {props.children}

      <div className={css.footer}>
        <NavLinks />
        <PreorderButton />
      </div>
    </div>
  );
}

const NavContext = createContext({ setOpen: () => {}, setClosed: () => {} });

export function useNavContext() {
  return useContext(NavContext);
}

export function HamburgerButton() {
  const { setOpen } = useContext(NavContext);

  return (
    <button type="button" onClick={setOpen}>
      <Image src={menu} alt="Menu" width={40} height={40} />
    </button>
  );
}

export function BackButton(props: { href: string }) {
  return (
    <Link href={props.href} className={css.backButton}>
      <Image
        src={arrowLeft}
        alt="Back"
        width={21}
        height={24}
        className={css.backButtonIcon}
      />
    </Link>
  );
}

export function Nav(props: {
  children: ReactNode;
  title: ReactNode;
  action: ReactNode;
  menu: ReactNode;
}) {
  const { isOpen, setOpen, setClosed, store } = useDialog();
  const pathname = usePathname();

  useEffect(() => {
    setClosed();
  }, [pathname, setClosed]);

  useEffect(() => {
    const query = window.matchMedia(MinWidth.MEDIUM);
    const handleChange = (media: MediaQueryListEvent) => {
      if (media.matches) {
        setClosed();
      }
    };

    query.addEventListener("change", handleChange);

    return () => {
      query.removeEventListener("change", handleChange);
    };
  }, [setClosed]);

  const context = useMemo(() => ({ setOpen, setClosed }), [setOpen, setClosed]);

  return (
    <NavContext.Provider value={context}>
      <div className={css.headerNav}>
        {isOpen && (
          <ModalDialog store={store} className={css.mobileNavDialog}>
            <div className={css.mobileNav}>
              <SideNav>{props.children}</SideNav>
            </div>
          </ModalDialog>
        )}

        {props.menu}

        <div>{props.title}</div>

        <div className={css.headerNavAction}>{props.action}</div>
      </div>

      <div className={css.sideNavContainer}>
        <SideNav>{props.children}</SideNav>
      </div>
    </NavContext.Provider>
  );
}

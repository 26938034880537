import { Army } from "@/domain/army";
import { Unit } from "@/domain/unit";
import { classNames } from "@/lib/class-list";
import { ArmyData } from "@/schema/latest";
import { useArmyOp } from "@/ui/army-provider/context";
import { Button } from "@/ui/button";
import { useDatabase } from "@/ui/database-provider";
import { KnownErrorMessage } from "@/ui/db-error";
import { RulesetProvider } from "@/ui/game-content-provider";
import { useDialog } from "@/ui/hooks/use-dialog";
import { useRouter } from "@/ui/hooks/use-router";
import { Image } from "@/ui/image";
import { Link } from "@/ui/link";
import { ListView } from "@/ui/list-view";
import { LoadingIndicator } from "@/ui/loading-indicator";
import { MobileNavTitle } from "@/ui/mobile-nav-title";
import { BackButton, Nav } from "@/ui/nav";
import { Paragraphs } from "@/ui/paragraphs";
import { ShareArmyDialog } from "@/ui/share-army-dialog";
import { SidenavTitle } from "@/ui/sidenav-title";
import { ToolLayoutV2 } from "@/ui/tool-layout";
import { UnitFormDialog } from "@/ui/unit-form-dialog";
import { button, interactiveText, primaryTheme } from "@/ui/utils.css";
import { ComponentType, ReactNode } from "react";
import { Route, Switch, useRoute } from "wouter";
import { ArmyActionsMenu } from "../army-actions-menu";
import { ArmyIssues } from "../army-issues";
import { KeywordsHandler } from "../keywords-handler";
import { UnitMiniIcons } from "../mini-icon";
import { UnitActionsMenu } from "../unit-actions-menu";
import { UnitCount } from "../unit-count";
import { UnitDetailView } from "../unit-detail-page";
import { UpgradeArmyRulesetDialog } from "../upgrade-army-ruleset-dialog";
import { DesktopViewHeader } from "./desktop-view-header";
import * as css from "./style.css";

export function ArmyDetailUnitCard(props: { unit: Unit; href: string }) {
  const [match] = useRoute(props.href);
  const { unit } = props;

  return (
    <Link
      href={props.href}
      className={css.unitLink}
      aria-current={match ? "true" : "false"}
    >
      <h2 className={css.unitHeading}>
        {unit.name}

        <UnitCount count={unit.count} />

        <UnitMiniIcons keywords={unit.keywords} />
      </h2>

      <div className={css.unitSummary}>
        {!unit.isValid() && (
          <>
            <span style={{ color: "red" }}>Invalid</span>
            {" · "}
          </>
        )}
        <span>{unit.summary}</span>

        {unit.keywords.length > 0 && (
          <span className={css.unitKeywords} style={{ marginTop: "0.25rem" }}>
            {" · "}
            {unit.keywordsSummary}
          </span>
        )}
      </div>
    </Link>
  );
}

function AddUnitButton(props: { armyId: string }) {
  const database = useDatabase();
  const setLocation = useRouter();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <UnitFormDialog
          heading="Unit"
          submit="Add Unit"
          store={store}
          onSubmit={async (values) => {
            const unitId = await database.addUnit(props.armyId, values);
            setLocation(`/army/a/${props.armyId}/u/${unitId}`);
          }}
        />
      )}

      <Button onClick={setOpen} className={button} style={primaryTheme}>
        Add Unit
      </Button>
    </>
  );
}

function ShareArmyButton(props: {
  armyData: ArmyData;
  armyShareTitle: string;
}) {
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <ShareArmyDialog
          store={store}
          armyData={props.armyData}
          armyShareTitle={props.armyShareTitle}
        />
      )}

      <button className={button} type="button" onClick={setOpen}>
        Share army
      </button>
    </>
  );
}

function CoverImage(props: { imageId: string }) {
  const images: Record<string, string> = {
    DARK_KNIGHT: "/images/dark-knight.webp",
    DWARF_ARCHER: "/images/dwarf-archer.webp",
  };

  const imageSrc = images[props.imageId];

  if (imageSrc) {
    return (
      <Image
        src={imageSrc}
        width={600}
        height={400}
        className={css.armyImage}
      />
    );
  }

  return null;
}

function UpdateArmyRulesetButton(props: { army: Army }) {
  const { store, isOpen, setOpen } = useDialog();
  return (
    <>
      {isOpen && <UpgradeArmyRulesetDialog store={store} army={props.army} />}

      <Button className={interactiveText} onClick={setOpen}>
        Upgrade ruleset?
      </Button>
    </>
  );
}

export function ArmyPageLayout(props: {
  army: Army;
  armyPath: string;
  unitPath: string;
  stickyButton?: ReactNode;
  secondaryButton?: ReactNode;
  renderUnit: (unit: Unit) => ReactNode;
  unitDetailComponent: ComponentType<{
    params: { unitId: string; armyId: string };
  }>;
  actionsMenu?: ReactNode;
  updateArmyButton?: ReactNode;
  headerLabel: string;
}) {
  const [armyViewIsMatching] = useRoute(props.armyPath);
  const [unitViewIsMatching] = useRoute(props.unitPath);
  const { army } = props;

  return (
    <KeywordsHandler>
      <div
        className={classNames(
          css.viewsContainer,
          armyViewIsMatching && css.armyViewIsMatching,
          unitViewIsMatching && css.unitViewIsMatching,
        )}
      >
        <div className={css.armyView}>
          <div className={css.view}>
            <div className={css.cover}>
              <DesktopViewHeader
                label={props.headerLabel}
                menu={props.actionsMenu}
              />

              <header
                className={
                  css.armyHeader[army.imageId ? "withImage" : "default"]
                }
              >
                {army.imageId && <CoverImage imageId={army.imageId} />}

                <h1 className={css.armyName}>{army.name}</h1>
                <div className={css.armySummary}>{army.summary}</div>
              </header>

              <ArmyIssues
                army={army}
                updateArmyButton={props.updateArmyButton}
              />

              {army.description && (
                <div className={css.description}>
                  <Paragraphs text={army.description} />
                </div>
              )}
            </div>

            <ListView
              items={props.army.units.map(props.renderUnit)}
              stickyButton={props.stickyButton}
              secondaryButton={props.secondaryButton}
            />
          </div>
        </div>

        <div className={css.unitView}>
          <Switch>
            <Route
              path={props.unitPath}
              component={props.unitDetailComponent}
            />

            <Route>
              <div className={css.unitEmptyState}>
                <Image
                  src="/images/magic_banner.webp"
                  alt=""
                  width={600}
                  height={400}
                  className={css.unitEmptyStateImage}
                />
                <div className={css.unitEmptyStateHint}>Select a unit</div>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </KeywordsHandler>
  );
}

export function ArmyDetailPageContent() {
  const armyOp = useArmyOp();

  if (armyOp.isPending) {
    return <LoadingIndicator />;
  }

  if (armyOp.isFailure) {
    return <KnownErrorMessage error={armyOp.failure} />;
  }

  const army = armyOp.value;

  return (
    <RulesetProvider ruleset={army.ruleset}>
      <ArmyPageLayout
        army={army}
        armyPath="/army/a/:armyId"
        unitPath="/army/a/:armyId/u/:unitId"
        actionsMenu={<ArmyActionsMenu army={army} />}
        headerLabel="Army"
        renderUnit={(unit) => {
          return (
            <ArmyDetailUnitCard
              href={`/army/a/${army.id}/u/${unit.id}`}
              key={unit.id}
              unit={unit}
            />
          );
        }}
        unitDetailComponent={UnitDetailView}
        stickyButton={<AddUnitButton armyId={army.id} />}
        secondaryButton={
          <ShareArmyButton
            armyData={army.data}
            armyShareTitle={`${army.name} (${army.summary})`}
          />
        }
        updateArmyButton={<UpdateArmyRulesetButton army={army} />}
      />
    </RulesetProvider>
  );
}

function UnitNav(props: { params: { armyId: string; unitId: string } }) {
  const armyOp = useArmyOp();
  const unit = armyOp.valueOrNull()?.getUnit(props.params.unitId);

  return (
    <Nav
      title={<MobileNavTitle title="Unit" />}
      menu={<BackButton href={`/army/a/${props.params.armyId}`} />}
      action={unit && <UnitActionsMenu unit={unit} iconColor="white" />}
    >
      <SidenavTitle>Army Builder</SidenavTitle>
    </Nav>
  );
}

function ArmyNav() {
  const armyOp = useArmyOp();
  const army = armyOp.valueOrNull();

  return (
    <Nav
      title={<MobileNavTitle title="Army" />}
      menu={<BackButton href="/army" />}
      action={army && <ArmyActionsMenu army={army} iconColor="white" />}
    >
      <SidenavTitle>Army Builder</SidenavTitle>
    </Nav>
  );
}

export function ArmyDetailPage() {
  return (
    <ToolLayoutV2
      nav={
        <Switch>
          <Route path="/army/a/:armyId/u/:unitId" component={UnitNav} />
          <Route path="/army/a/:armyId" component={ArmyNav} />
        </Switch>
      }
    >
      <ArmyDetailPageContent />
    </ToolLayoutV2>
  );
}

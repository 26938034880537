export type Card = {
  id: string;
  value: string;
  suite: string;
};

export class Deck {
  cards: Card[];
  dealer: Generator<Card, never, never>;

  constructor(data: { suites: string[]; cards: string[] }) {
    this.cards = data.suites.flatMap((suite) =>
      data.cards.map((value) => ({
        suite,
        value,
        id: `${value}${suite}`,
      })),
    );

    this.dealer = this.createDealer(this.cards);
  }

  *createDealer<T>(deck: T[]): Generator<T, never, never> {
    let index = 0;

    while (true) {
      yield deck[index % deck.length];
      index++;
    }
  }

  take(count: number) {
    const cards: this["cards"] = [];

    for (let index = 0; index < count; index++) {
      cards.push(this.dealer.next().value);
    }

    return cards;
  }
}

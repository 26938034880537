import { formatCardFromId } from "@/ui/fortune/card-formatters";
import { FortuneCard } from "@/ui/fortune/fortune-card";
import { useIntersectionObserver } from "@/ui/intersection-observer-list";
import { Link } from "@/ui/link";
import { ScrollControls } from "@/ui/scroll-controls";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import * as css from "./style.css";
import { Ruleset } from "@/domain/ruleset";
import { useDatabase } from "@/ui/database-provider";
import { FortuneData } from "@/schema/latest";

export function FortuneHand(props: { fortune: FortuneData; ruleset: Ruleset }) {
  const database = useDatabase();
  const playingCardsIds = props.fortune.playing_cards_ids;
  const noFortunes = playingCardsIds.length === 0;
  const key = playingCardsIds.join(".");

  const { containerRef, setElementRef, ...scrollControls } =
    useIntersectionObserver<HTMLDivElement>({ key });

  return (
    <section className={css.section}>
      <h2 className={css.heading[noFortunes ? "hidden" : "visible"]}>
        Your Hand
      </h2>

      {noFortunes ?
        <div className={css.noFortunes}>
          <div className={css.noFortunesInfo}>
            <h3 className={css.noFortunesHeading}>Your hand is empty.</h3>
            <p className={css.noFortunesHint}>
              Select cards below to add them to your hand. Not sure how to use
              this tool? <Link href="/fortune/help">Learn more</Link>.
            </p>

            <div className={css.noFortunesGraphic}>
              <div className={css.noFortunesCard.left} />
              <div className={css.noFortunesCard.center} />
              <div className={css.noFortunesCard.right} />
            </div>
          </div>
        </div>
      : <div>
          <div
            ref={containerRef}
            className={css.fortunes}
            style={assignInlineVars({
              [css.cardsCount]: `${playingCardsIds.length}`,
            })}
          >
            {playingCardsIds.map((cardId, index) => {
              const fortune = props.ruleset.fortunesByCardId.getOrThrow(cardId);

              return (
                <div
                  id={cardId}
                  key={cardId}
                  className={css.fortune}
                  style={assignInlineVars({
                    [css.delay]: `${index * 100}ms`,
                  })}
                  ref={setElementRef(cardId)}
                >
                  <FortuneCard
                    cardId={cardId}
                    name={fortune.name}
                    description={fortune.instructions}
                    label={formatCardFromId(cardId)}
                    onClick={async () => {
                      await database.updateFortuneHand({
                        ...props.fortune,
                        playing_cards_ids:
                          props.fortune.playing_cards_ids.filter(
                            (id) => id !== cardId,
                          ),
                      });
                    }}
                    buttonLabel="Discard"
                  />
                </div>
              );
            })}
          </div>
        </div>
      }

      <div className={css.scrollControls[noFortunes ? "hidden" : "visible"]}>
        <ScrollControls
          {...scrollControls}
          sectionsIds={playingCardsIds}
          theme="light"
        />
      </div>
    </section>
  );
}

import { Button } from "../button";
import { usePromptState } from "../hooks/use-prompt-state";
import { Link } from "../link";
import { useSession } from "../session-provider/context";
import { interactiveText, primaryTheme } from "../utils.css";
import * as css from "./style.css.js";

export function MaybeSignupPrompt(props: { armiesCount: number }) {
  const { isRetrievingSession, isLoggedIn } = useSession();
  const [dismissedAt, setDismissedAt] = usePromptState(
    "join_prompt_dismissed_at",
  );

  const hasLessThanThreeArmies = props.armiesCount < 3;
  const wasPreviouslyDismissed = !!dismissedAt;

  if (
    isRetrievingSession ||
    isLoggedIn ||
    wasPreviouslyDismissed ||
    hasLessThanThreeArmies
  ) {
    return null;
  }

  const learn_more = (
    <Link className={interactiveText} href="/help/backup-and-sync">
      Learn more
    </Link>
  );

  return (
    <div className={css.prompt}>
      <div className={css.text}>
        <strong>Did you know?</strong> You can use a <em>free</em> Indie
        Tabletop Club account to backup your armies and sync them across any
        number of devices. {learn_more}.
      </div>

      <div className={css.actions}>
        <a href="/join" className={css.join} style={primaryTheme}>
          Join
        </a>

        <Button
          className={css.dismiss}
          onClick={() => {
            setDismissedAt(Date.now());
          }}
        >
          No thanks
        </Button>
      </div>
    </div>
  );
}

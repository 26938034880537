import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useRuleset } from "./game-content-provider";
import { useDialog } from "./hooks/use-dialog";
import { KeywordPopup } from "./keyword-popup";

type ValidTargetElement = HTMLElement & {
  dataset: { keywordId: string };
};

const Keywords = createContext<(event: ValidTargetElement) => void>(() => {});

export const useKeywords = () => useContext(Keywords);

export function KeywordsHandler(props: { children: ReactNode }) {
  const ruleset = useRuleset();
  const callerRef = useRef<HTMLElement | null>(null);
  const [keyword, setKeyword] = useState<{ id: string; value?: string } | null>(
    null,
  );
  const { isOpen, setOpen, setClosed, store } = useDialog();

  const set = useCallback(
    (target: ValidTargetElement | null) => {
      if (target !== null) {
        callerRef.current = target; // Remember caller
        setKeyword({
          id: target.dataset.keywordId,
          value: target.dataset.keywordValue,
        });
        setOpen();
      } else {
        if (callerRef.current) {
          callerRef.current.focus();
        }
        callerRef.current = null;
        setKeyword(null);
        setClosed();
      }
    },
    [setClosed, setOpen],
  );

  return (
    <Keywords.Provider value={set}>
      {props.children}

      {isOpen && keyword && (
        <KeywordPopup
          keywordDef={ruleset.keywordsDefsById.getOrThrow(keyword.id)}
          store={store}
        />
      )}
    </Keywords.Provider>
  );
}

import { useDialog } from "@/ui/hooks/use-dialog";
import { Image } from "@/ui/image";
import { useBattleStateOp } from "../battle-state/context";
import { ShareBattleDialog } from "../share-battle-dialog";
import * as css from "./style.css";

export function ShareBattleButton() {
  const { isOpen, setOpen, store } = useDialog();
  const battleStateOp = useBattleStateOp();

  if (!battleStateOp.isSuccess) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <ShareBattleDialog
          store={store}
          battleState={battleStateOp.value.state}
        />
      )}

      <button type="button" className={css.button} onClick={setOpen}>
        <Image
          src="/icons/share.svg"
          alt="Share"
          className={css.icon}
          width={40}
          height={40}
        />
      </button>
    </>
  );
}

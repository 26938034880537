import { ArmyData } from "@/schema/latest";

type DataMigration = {
  from: string;
  to: string;
  rename_keyword?: Record<string, string>;
  changelog_url: string;
};

const v0_3_0: DataMigration = {
  from: "0.2.0",
  to: "0.3.0",
  changelog_url: "/changelog/early-access-v3",
  rename_keyword: {
    large: "towering",
    quick_fire: "quick_shot",
  },
};

const v1_0_0: DataMigration = {
  from: "0.3.0",
  to: "1.0.0",
  changelog_url: "/changelog/2024-07-08-hobgoblin-v1.0",
};

const migrations = new Map([v0_3_0, v1_0_0].map((m) => [m.from, m]));

/**
 * Upgrades army data to the latest ruleset.
 */
export function migrateArmy(armyData: ArmyData) {
  const migration = migrations.get(armyData.ruleset_version);

  // If there are no more migrations to run, we return the army data.
  if (!migration) {
    return armyData;
  }

  let newArmyData = {
    ...armyData,
    ruleset_version: migration.to,
  };

  const renameKeyword = migration.rename_keyword;
  if (renameKeyword) {
    newArmyData = {
      ...newArmyData,
      units: armyData.units.map((unit) => {
        return {
          ...unit,
          keywords: unit.keywords.map((keyword) => {
            const newName = renameKeyword[keyword.id];
            if (newName) {
              return { ...keyword, id: newName };
            }
            return keyword;
          }),
        };
      }),
    };
  }

  return migrateArmy(newArmyData);
}

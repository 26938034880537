export function pluralize(
  count: number,
  singular: string,
  plural: string,
  options?: { stylistic?: boolean },
) {
  const count_ = options?.stylistic ? formatCount(count) : count;

  if (count === 1) {
    return `${count_} ${singular}`;
  }

  return `${count_} ${plural}`;
}

export function formatCount(count: number) {
  switch (count) {
    case 0:
      return "zero";
    case 1:
      return "one";
    case 2:
      return "two";
    case 3:
      return "three";
    case 4:
      return "four";
    case 5:
      return "five";
    case 6:
      return "six";
    case 7:
      return "seven";
    case 8:
      return "eight";
    case 9:
      return "nine";
    case 10:
      return "ten";

    default:
      return `${count}`;
  }
}

export function ordinal(n: number) {
  const num = n.toString();

  if (num.endsWith("1")) {
    return `${num}st`;
  }

  if (num.endsWith("2")) {
    return `${num}nd`;
  }

  if (num.endsWith("3")) {
    return `${num}rd`;
  }

  return `${num}th`;
}

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

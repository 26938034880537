import { ReactNode } from "react";
import * as css from "./style.css";
import { ViewContainer } from "../max-width-container";

export function InfoPage(props: { children: ReactNode }) {
  return (
    <ViewContainer>
      <main className={css.main}>{props.children}</main>
    </ViewContainer>
  );
}

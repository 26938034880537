import { Army } from "@/domain/army";
import { ArmyValidationError } from "@/domain/validation";
import { pluralize } from "@/lib/text";
import { useGameContentLookup } from "@/ui/game-content-provider";
import { ReactNode } from "react";
import { IssuesList } from "../issues-list";
import * as css from "./style.css";

type OutdateRulesetIssue = {
  type: "OUTDATED_RULESET";
  army: Army;
  latestRulesetName: string;
};

export type ArmyIssue = ArmyValidationError | OutdateRulesetIssue;

export function ArmyIssues(props: {
  army: Army;
  updateArmyButton?: ReactNode;
}) {
  const { army } = props;
  const { latestRuleset } = useGameContentLookup();
  const isOutdated = props.army.ruleset.version < latestRuleset.version;
  const issues: ArmyIssue[] =
    isOutdated ?
      [
        {
          type: "OUTDATED_RULESET",
          latestRulesetName: latestRuleset.versionName,
          army,
        },
        ...army.issues,
      ]
    : army.issues;

  if (issues.length === 0) {
    return null;
  }

  return (
    <div className={css.armyIssues}>
      <IssuesList
        issues={issues.map((issue) => {
          switch (issue.type) {
            case "MISSING_GENERAL": {
              return `An army must contain a unit with the General keyword.`;
            }

            case "MISSING_BATTLE_STANDARD": {
              return `An army must contain a unit with the Battle Standard keyword.`;
            }

            case "INVALID_UNITS": {
              const units = pluralize(issue.units.length, "is", "are", {
                stylistic: true,
              });
              const unitList = issue.units.map((unit) => unit.name).join(", ");
              return `An army must not contain any invalid units, but ${units} present: ${unitList}.`;
            }

            case "OUTDATED_RULESET": {
              const message = `This army is using the ${issue.army.ruleset.versionName} ruleset. The latest version is ${issue.latestRulesetName}. `;

              return {
                iconSrc: "/icons/quill.svg",
                message: (
                  <>
                    {message}
                    {props.updateArmyButton}
                  </>
                ),
              };
            }
          }
        })}
      />
    </div>
  );
}

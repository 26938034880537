import { useDialogStore } from "@ariakit/react";

export function useDialog(props?: { initialIsOpen?: boolean }) {
  const store = useDialogStore({
    animated: true,
    defaultOpen: props?.initialIsOpen,
  });
  const isOpen = store.useState("open");
  const isAnimating = store.useState("animating");

  return {
    isOpen: isAnimating || isOpen,
    setOpen: store.show,
    setClosed: store.hide,
    store,
  };
}

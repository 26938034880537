import { toVersionName } from "@/lib/semver";
import { useDialog } from "@/ui/hooks/use-dialog";
import { Image } from "@/ui/image";
import { Popup } from "@/ui/popup";
import * as css from "./style.css";

export function VersionMismatchPopover(props: {
  battleStateGameVersion: string;
  battleSettingsGameVersion: string;
}) {
  const versionMismatch =
    props.battleSettingsGameVersion !== props.battleStateGameVersion;

  const { store } = useDialog({ initialIsOpen: versionMismatch });

  return (
    <Popup
      store={store}
      label={
        <div className={css.label}>
          <Image
            src="/icons/quill.svg"
            width={40}
            height={40}
            style={{ margin: "-0.5rem" }}
          />

          <div className={css.heading}>Version Mismatch</div>
        </div>
      }
      theme="light"
      modal={false}
    >
      <div>
        This battle setup was shared using the{" "}
        {toVersionName(props.battleStateGameVersion)} ruleset, but you are using{" "}
        {toVersionName(props.battleSettingsGameVersion)}. Opened as read‑only.
      </div>
    </Popup>
  );
}

import { useDatabase } from "@/ui/database-provider";
import { getRandomArmyName } from "@/lib/name-generator";
import { FormDialog } from "@/ui/form-dialog";
import { useField } from "@/ui/hooks/use-field";
import { InlineButton } from "@/ui/inline-button";
import { TextAreaField, TextField } from "@/ui/inputs";
import { InputsGroup } from "@/ui/inputs/group";
import { DialogStore } from "@ariakit/react";
import { ArmyData } from "@/schema/latest";

type FormValues = {
  name: string;
  description: string;
};

type ArmyFormDialogProps = {
  store: DialogStore;
  heading: string;
  submit: string;
  initialValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => Promise<void>;
};

function ArmyFormDialog(props: ArmyFormDialogProps) {
  const [nameField, name, setName] = useField(props.initialValues?.name);
  const [descField, description] = useField(props.initialValues?.description);

  return (
    <FormDialog
      heading={props.heading}
      submit={props.submit}
      store={props.store}
      onSubmit={() => {
        return props.onSubmit({
          name,
          description,
        });
      }}
    >
      <InputsGroup>
        <TextField
          {...nameField}
          label="Name"
          placeholder="Eg. Blackmire Expedition"
          hint={
            <>
              Stuck?{" "}
              <InlineButton
                onClick={() => {
                  setName(getRandomArmyName());
                }}
              >
                Generate a random name
              </InlineButton>
              .
            </>
          }
        />

        <TextAreaField
          {...descField}
          label="Description"
          minRows={4}
          placeholder="Optionally add a backstory, how to play etc..."
        />
      </InputsGroup>
    </FormDialog>
  );
}

export function EditArmyDialog(props: {
  armyData: ArmyData;
  store: DialogStore;
}) {
  const database = useDatabase();

  return (
    <ArmyFormDialog
      store={props.store}
      heading="Edit Army"
      submit="Save"
      initialValues={{
        name: props.armyData.name,
        description: props.armyData.description,
      }}
      onSubmit={async (values) => {
        await database.updateArmy({
          ...props.armyData,
          ...values,
        });
      }}
    />
  );
}

export function CreateArmyDialog(
  props: Omit<ArmyFormDialogProps, "heading" | "submit">,
) {
  return <ArmyFormDialog {...props} heading="Army" submit="Create Army" />;
}

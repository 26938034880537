declare global {
  interface ImportMetaEnv {
    VITE_ENABLE_SENTRY?: string;
    VITE_ERRORS_VISIBILITY?: string;
    VITE_VERCEL_GIT_COMMIT_SHA?: string;
    VITE_VERCEL_GIT_COMMIT_REF?: string;
    VITE_ORY_SDK_URL: string;
  }
}

export const config = {
  ENABLE_SENTRY: import.meta.env.VITE_ENABLE_SENTRY === "true",
  EXPOSE_ERRORS: import.meta.env.VITE_ERRORS_VISIBILITY === "expose",
  GIT_COMMIT_SHA: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
  GIT_COMMIT_REF: import.meta.env.VITE_VERCEL_GIT_COMMIT_REF,
  ORY_SDK_URL: import.meta.env.VITE_ORY_SDK_URL,
};

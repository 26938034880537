import { Button, ButtonProps } from "@/ui/button";
import { useDialog } from "@/ui/hooks/use-dialog";
import { DialogStore } from "@ariakit/react";
import { ReactElement } from "react";


export function DialogButton(props: Omit<ButtonProps, "onClick"> & {
  renderDialog: (props: { store: DialogStore; }) => ReactElement;
}) {
  const { renderDialog, ...pass } = props;
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && renderDialog({ store })}
      <Button {...pass} onClick={setOpen} />
    </>
  );
}

import { Ruleset } from "@/domain/ruleset";
import { Pending, Success } from "@/lib/async-op";
import { SectionId, UserSettings } from "@/lib/user-settings";
import {
  getRandomBattlefield,
  getRandomDeployment,
  getRandomScenario,
  getRandomTwist,
} from "@/ui/actions";
import { useResolveVersion } from "@/ui/game-content-provider";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useLocation } from "wouter";
import { useUserSettingsOp } from "../../ui/user-settings/context";
import { BattleState, BattleStateContext } from "./context";

type StateParams = {
  rulesetVersion: string;
  scenarioId: string;
  deploymentId: string;
  twistId: string;
  battlefieldIds: string[];
};

function getBattleStateFromParams(
  ruleset: Ruleset,
  params: StateParams,
): BattleState {
  return {
    ruleset,
    scenario: ruleset.scenariosById.getOr(params.scenarioId, null),
    battlefield: params.battlefieldIds.flatMap((battlefieldId) => {
      return ruleset.battlefieldsById.getOr(battlefieldId, null) ?? [];
    }),
    deployment: ruleset.deploymentsById.getOr(params.deploymentId, null),
    twist: ruleset.twistsById.getOr(params.twistId, null),
  };
}

function getStateParams(searchParams: URLSearchParams) {
  const scenarioId = searchParams.get("scenario");
  const deploymentId = searchParams.get("deployment");
  const twistId = searchParams.get("twist");
  const battlefieldIds = searchParams.getAll("battlefield");

  // Prior to v0.3.0, game version was not specified in the share URL
  const rulesetVersion =
    searchParams.get("ruleset_version") ??
    searchParams.get("game_version") ??
    "0.2.0";

  if (scenarioId && deploymentId && twistId && battlefieldIds.length > 0) {
    return {
      rulesetVersion,
      scenarioId,
      deploymentId,
      twistId,
      battlefieldIds,
    };
  }

  // Could not get state from search params
  return null;
}

function getRandomState(
  userSettings: UserSettings,
  currentState?: BattleState | null,
): BattleState {
  const { ruleset } = userSettings;

  return {
    ruleset: userSettings.ruleset,
    scenario: getRandomScenario(ruleset.scenariosByRoll, [
      ...userSettings.excludedScenariosIds,
      currentState?.scenario?.id,
    ]),
    battlefield: getRandomBattlefield(ruleset.battlefieldsByRoll, [
      ...userSettings.excludedBattlefieldsIds,
      currentState?.battlefield?.[0]?.id,
    ]),
    deployment: getRandomDeployment(ruleset.deploymentsByRoll, [
      ...userSettings.excludedDeploymentsIds,
      currentState?.deployment?.id,
    ]),
    twist: getRandomTwist(ruleset.twistsByRoll, [
      ...userSettings.excludedTwistsIds,
      currentState?.twist?.id,
    ]),
  };
}

function getRandomSectionState(
  battleSettings: UserSettings,
  battleState: BattleState,
  sectionId: SectionId,
) {
  const { ruleset } = battleSettings;

  switch (sectionId) {
    case "scenario":
      return getRandomScenario(ruleset.scenariosByRoll, [
        ...battleSettings.excludedScenariosIds,
        battleState.scenario?.id,
      ]);

    case "battlefield":
      return getRandomBattlefield(ruleset.battlefieldsByRoll, [
        ...battleSettings.excludedBattlefieldsIds,
        battleState.battlefield?.[0]?.id,
      ]);

    case "twist":
      return getRandomTwist(ruleset.twistsByRoll, [
        ...battleSettings.excludedTwistsIds,
        battleState.twist?.id,
      ]);

    case "deployment":
      return getRandomDeployment(ruleset.deploymentsByRoll, [
        ...battleSettings.excludedDeploymentsIds,
        battleState.deployment?.id,
      ]);

    default:
      throw new Error("Section key not recognized.");
  }
}

export function BattleStateProvider(props: { children: ReactNode }) {
  const [_location, setLocation] = useLocation();
  const userSettingsOp = useUserSettingsOp();
  const paramsState = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return getStateParams(searchParams);
  }, []);
  const resolveRulesetOp = useResolveVersion(paramsState?.rulesetVersion);

  const [state, setState] = useState<BattleState | null>(null);

  // State cannot be set initially since we rely async operations.
  useEffect(() => {
    // We only want to update state if it is currently null
    if (state === null) {
      // If params state is set, we want to attempt to get state from params,
      // however we only want to do that if rulesetResult is a success.
      if (paramsState) {
        if (resolveRulesetOp.isSuccess) {
          setState(
            getBattleStateFromParams(resolveRulesetOp.value, paramsState),
          );
        }

        // Otherwise generate random state once user settings are ready.
      } else {
        if (userSettingsOp.isSuccess) {
          setState(getRandomState(userSettingsOp.value));
        }
      }
    }
  }, [userSettingsOp, state, resolveRulesetOp, paramsState]);

  const context = useMemo(() => {
    if (userSettingsOp.isFailure) {
      return userSettingsOp;
    }

    if (resolveRulesetOp.isFailure) {
      return resolveRulesetOp;
    }

    if (userSettingsOp.isSuccess) {
      if (state) {
        // If state is set and user settings have been loaded, we return a successful
        // state container.
        return new Success({
          state,
          actions: {
            rerollAll: () => {
              setState(getRandomState(userSettingsOp.value, state));
              setLocation("/battle", { replace: true });
            },

            rerollSection: (sectionId: SectionId) => {
              const nextState: BattleState = {
                ...state,
                [sectionId]: getRandomSectionState(
                  userSettingsOp.value,
                  state,
                  sectionId,
                ),
              };
              setState(nextState);
              setLocation("/battle", { replace: true });
            },
          },
        });
      }

      // If state is not yet set, we return a pending marker
      return new Pending();
    }

    return new Pending();
  }, [userSettingsOp, resolveRulesetOp, state, setLocation]);

  return (
    <BattleStateContext.Provider value={context}>
      {props.children}
    </BattleStateContext.Provider>
  );
}

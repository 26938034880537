export enum Trait {
  WEAKNESS = "Weakness",
  STRENGTH = "Strength",
  TERRAIN = "Terrain",
  FOOTPRINT = "Footprint",
  CHARACTER = "Character",
  RESERVED = "Reserved",
}

export const TraitValues = Object.values(Trait);

export function isTrait(t: string): t is Trait {
  return (TraitValues as string[]).includes(t);
}

export function assertTrait(s: string): asserts s is Trait {
  if (!isTrait(s)) {
    throw new Error(`${s} is not a Trait.`);
  }
}

export function toTrait(s: string): Trait {
  assertTrait(s);
  return s;
}

function stringifyTraits(traits: Trait[]) {
  return traits.map((trait) => `<i>${trait}</i>. `).join("");
}

export function injectTraits(description: string, traits: Trait[]) {
  return description.replace("<p>", `<p>${stringifyTraits(traits)}`);
}

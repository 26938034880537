import { KeywordDefinition } from "@/domain/keyword-definition";
import { Body } from "@/ui/body";
import { DialogStore } from "@ariakit/react";
import { Popup } from "@/ui/popup";
import * as css from "./style.css";

export function KeywordPopup(props: {
  keywordDef: KeywordDefinition;
  store: DialogStore;
}) {
  return (
    <Popup
      label={<div className={css.label}>Keyword</div>}
      store={props.store}
      modal={true}
      theme="dark"
    >
      <h3 className={css.heading}>{props.keywordDef.name}</h3>

      <Body
        html={props.keywordDef.descriptionWithTraits}
        omitReferenceFor={props.keywordDef.name}
      />
    </Popup>
  );
}

import { ReactNode } from "react";
import { useIntersectionObserver } from "@/ui/intersection-observer-list";
import { ScrollControls } from "@/ui/scroll-controls";
import * as css from "./style.css";
import { Image } from "@/ui/image";

export function SectionsContainer(props: { children: ReactNode[] }) {
  const sections = props.children;

  const { containerRef, setElementRef, ...scrollControls } =
    useIntersectionObserver({ key: sections.length.toString() });

  return (
    <main className={css.container} ref={containerRef}>
      {sections.map((section, index) => {
        const id = index.toString();

        return (
          <div id={id} key={id} className={css.track} ref={setElementRef(id)}>
            {section}
          </div>
        );
      })}

      <div className={css.spacer}>
        <Image
          className={css.illustration}
          src="/images/sword.webp"
          width={140}
          height={420}
        />
      </div>

      <div className={css.scrollControls}>
        <ScrollControls
          {...scrollControls}
          sectionsIds={sections.map((_, index) => index.toString())}
        />
      </div>
    </main>
  );
}

import { useDatabase } from "@/ui/database-provider";
import { Unit } from "@/domain/unit";
import { ConfirmDialog } from "@/ui/confirm-dialog";
import { useDialog } from "@/ui/hooks/use-dialog";
import { useRouter } from "@/ui/hooks/use-router";
import { MoreMenu, MoreMenuItem, MoreMenuSeparator } from "../more-menu";
import { UnitFormDialog } from "../unit-form-dialog";
import { primaryTheme } from "@/ui/utils.css";

function EditUnitMenuItem(props: { unit: Unit }) {
  const database = useDatabase();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <UnitFormDialog
          heading="Edit Unit"
          submit="Save"
          store={store}
          initialValues={{
            name: props.unit.data.name,
            description: props.unit.data.description,
            type: props.unit.data.type,
          }}
          onSubmit={async (values) => {
            await database.updateUnit(
              props.unit.army.id,
              props.unit.id,
              values,
            );
          }}
        />
      )}

      <MoreMenuItem onClick={setOpen}>Edit</MoreMenuItem>
    </>
  );
}

function DuplicateUnitMenuItem(props: { unit: Unit }) {
  const database = useDatabase();
  const setLocation = useRouter();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          heading={`Duplicate unit?`}
          message={`Reserved keywords and cursed artefacts will not be copied over.`}
          store={store}
          onSubmit={async () => {
            const unitId = await database.duplicateUnit(props.unit.army.id, props.unit.data);
            setLocation(`/army/a/${props.unit.army.id}/u/${unitId}`);
          }}
          submit="Duplicate"
          submitTheme={primaryTheme}
        />
      )}

      <MoreMenuItem onClick={setOpen}>Duplicate</MoreMenuItem>
    </>
  );
}

function DeleteUnitMenuItem(props: { unit: Unit }) {
  const database = useDatabase();
  const setLocation = useRouter();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          heading={`Delete unit?`}
          message={`Are you sure you want to delete "${props.unit.name}"?`}
          store={store}
          onSubmit={async () => {
            await database.removeUnit(props.unit.army.id, props.unit.id);
            setLocation(`/army/a/${props.unit.army.id}`);
          }}
          submit="Delete"
        />
      )}

      <MoreMenuItem onClick={setOpen}>Delete</MoreMenuItem>
    </>
  );
}



export function UnitActionsMenu(props: { unit: Unit; iconColor?: string }) {
  return (
    <MoreMenu fallbackLabel="Unit actions" iconColor={props.iconColor}>
      <EditUnitMenuItem unit={props.unit} />
      <DuplicateUnitMenuItem unit={props.unit} />

      <MoreMenuSeparator />

      <DeleteUnitMenuItem unit={props.unit} />
    </MoreMenu>
  );
}

import { ReactNode } from "react";
import { container, title } from "./style.css";

export function MobileNavTitle(props: { title: ReactNode }) {
  return (
    <div className={container}>
      <h1 className={title}>{props.title}</h1>
    </div>
  );
}

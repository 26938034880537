import { Army } from "@/domain/army";
import { migrateArmy } from "@/domain/migrations";
import { ConfirmDialog } from "@/ui/confirm-dialog";
import { useDatabase } from "@/ui/database-provider";
import { useGameContentLookup } from "@/ui/game-content-provider";
import { Link } from "@/ui/link";
import { interactiveText, primaryTheme } from "@/ui/utils.css";
import { DialogStore } from "@ariakit/react";

export function UpgradeArmyRulesetDialog(props: {
  army: Army;
  store: DialogStore;
}) {
  const { latestRuleset } = useGameContentLookup();
  const database = useDatabase();

  return (
    <ConfirmDialog
      heading="Upgrade to latest ruleset?"
      message={
        <>
          {`This army will be upgraded to ${latestRuleset.versionName}. Army Points and keywords might change. `}
          <Link
            href="/changelog/2024-07-08-hobgoblin-v1.0"
            className={interactiveText}
          >
            See changelog
          </Link>
          .
        </>
      }
      store={props.store}
      onSubmit={async () => {
        await database.updateArmy(migrateArmy(props.army.data));
      }}
      submit="Upgrade"
      submitTheme={primaryTheme}
    />
  );
}

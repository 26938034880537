import { ReactNode } from "react";
import * as css from "./style.css";

/**
 * This component only renders children if core platform features are
 * supported in the browsers/web view that is rendering the app.
 *
 * Although some features have very wide browser support (over 95%),
 * embedded web views are not always feature complete and break the app.
 *
 * This happened with:
 *
 * - `BroadcastChannel` (no longer used) in the Facebook app on iOS
 * - `String.prototype.replaceAll` in Amazon Silk on Kindle
 */
export function CompatGuard(props: { children: ReactNode }) {
  const allSupported = "replaceAll" in String.prototype;

  if (!allSupported) {
    return (
      <div className={css.guard}>
        <h1 className={css.heading}>Baseline Web Features Unsupported</h1>
        <p className={css.description}>
          This browser does not support baseline web platform features that are
          required to run the Hobgoblin app. You might be using an outdated
          browser, or a browser embedded in a 3rd party app like Instagram,
          Messenger, Facebook, etc...
        </p>
        <p className={css.description}>
          To fix this issue, open the app in a modern — standalone — browser
          like Chrome, Safari, Firefox, or Edge.
        </p>

        <input
          type="text"
          className={css.urlInput}
          value={`${window.location}`}
          readOnly
        />

        <p className={css.hint}>
          You can copy the above link to paste it in a compatible browser.
        </p>
      </div>
    );
  }

  return <>{props.children}</>;
}

import { toVersionName } from "../lib/semver.js";
import { LookupMap } from "../lib/lookup.js";
import {
  AbyssalAllegianceDefinition,
  AbyssalAllegianceDefinitionData,
} from "./abyssal-allegiance.js";
import {
  CursedArtefactDefinition,
  CursedArtefactDefinitionData,
} from "./artefact.js";
import { Battlefield } from "./battlefield.js";
import { Deck } from "./deck.js";
import { Deployment } from "./deployment.js";
import { Fortune, FortuneData } from "./fortune.js";
import {
  KeywordDefinition,
  KeywordDefinitionData,
} from "./keyword-definition.js";
import { Scenario } from "./scenarios.js";
import { Twist } from "./twist.js";
import { UnitProfile, UnitProfileData } from "./units.js";
import { sortByName } from "./utils.js";

function toLookupById<T extends { id: string }>(items: T[]) {
  return new LookupMap(items.map((item) => [item.id, item]));
}

function toLookupByRoll<T extends { rolls: number[] }>(items: T[]) {
  return new LookupMap(
    items.flatMap((item) => item.rolls.map((roll) => [roll, item])),
  );
}

export type RulesetData = {
  version: string;
  artefacts: CursedArtefactDefinitionData[];
  keywords: KeywordDefinitionData[];
  units: UnitProfileData[];
  twist: Twist[];
  deployment: Deployment[];
  battlefield: Battlefield[];
  scenarios: Scenario[];
  fortunes: FortuneData[];
  allegiances: AbyssalAllegianceDefinitionData[];
};

export class Ruleset {
  readonly version: string;
  readonly artefacts: CursedArtefactDefinition[];
  readonly artefactsById: LookupMap<string, CursedArtefactDefinition>;
  readonly keywordsDefs: KeywordDefinition[];
  readonly keywordsDefsById: LookupMap<string, KeywordDefinition>;
  readonly unitProfiles: UnitProfile[];
  readonly unitProfilesByType: LookupMap<string, UnitProfile>;
  readonly twists: Twist[];
  readonly twistsById: LookupMap<string, Twist>;
  readonly twistsByRoll: LookupMap<number, Twist>;
  readonly deployments: Deployment[];
  readonly deploymentsById: LookupMap<string, Deployment>;
  readonly deploymentsByRoll: LookupMap<number, Deployment>;
  readonly battlefields: Battlefield[];
  readonly battlefieldsById: LookupMap<string, Deployment>;
  readonly battlefieldsByRoll: LookupMap<number, Deployment>;
  readonly scenarios: Scenario[];
  readonly scenariosById: LookupMap<string, Scenario>;
  readonly scenariosByRoll: LookupMap<number, Scenario>;
  readonly fortunes: Fortune[];
  readonly fortunesByCardId: LookupMap<string, Fortune>;
  readonly fortunesById: LookupMap<string, Fortune>;
  readonly abyssalAllegiances: AbyssalAllegianceDefinition[];
  readonly abyssalAllegiancesById: LookupMap<
    string,
    AbyssalAllegianceDefinition
  >;

  constructor(data: RulesetData) {
    this.version = data.version;

    this.keywordsDefs = data.keywords
      .map((data) => new KeywordDefinition(data))
      .sort(sortByName);
    this.keywordsDefsById = toLookupById(this.keywordsDefs);

    this.unitProfiles = data.units.map((data) => new UnitProfile(this, data));
    this.unitProfilesByType = new LookupMap(
      this.unitProfiles.map((profile) => [profile.type, profile]),
    );

    this.twists = data.twist;
    this.twistsById = toLookupById(this.twists);
    this.twistsByRoll = toLookupByRoll(this.twists);

    this.deployments = data.deployment;
    this.deploymentsById = toLookupById(this.deployments);
    this.deploymentsByRoll = toLookupByRoll(this.deployments);

    this.battlefields = data.battlefield;
    this.battlefieldsById = toLookupById(this.battlefields);
    this.battlefieldsByRoll = toLookupByRoll(this.battlefields);

    this.scenarios = data.scenarios;
    this.scenariosById = toLookupById(this.scenarios);
    this.scenariosByRoll = toLookupByRoll(this.scenarios);

    const deck = new Deck({
      suites: ["S", "H", "C", "D"],
      cards: ["2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K"],
    });
    this.fortunes = data.fortunes.map((data) => new Fortune(data, deck));
    this.fortunesById = toLookupById(this.fortunes);
    this.fortunesByCardId = new LookupMap(
      this.fortunes.flatMap((fortune) =>
        fortune.cards.map((card) => [card.id, fortune]),
      ),
    );

    this.artefacts = data.artefacts.map(
      (data) => new CursedArtefactDefinition(this, data),
    );
    this.artefactsById = toLookupById(this.artefacts);

    this.abyssalAllegiances = data.allegiances.map(
      (data) => new AbyssalAllegianceDefinition(data),
    );
    this.abyssalAllegiancesById = toLookupById(this.abyssalAllegiances);
  }

  get versionName() {
    return toVersionName(this.version);
  }
}

import { Unit } from "@/domain/unit";
import battleStandard from "@/images/battle_standard.svg";
import general from "@/images/general.svg";
import wizard from "@/images/wizard.svg";
import { Image } from "@/ui/image";
import * as css from "./style.css";

export function UnitMiniIcons(props: { keywords: Unit["keywords"] }) {
  const keywordsIds = new Set(props.keywords.map((k) => k.id));

  return (
    <>
      {" "}
      {keywordsIds.has("general") && <GeneralMiniIcon />}
      {keywordsIds.has("battle_standard") && <BattleStandardMiniIcon />}
      {keywordsIds.has("wizard") && <WizardMiniIcon />}
    </>
  );
}

export function KeywordMiniIcon(props: { keywordId: string }) {
  switch (props.keywordId) {
    case "general": {
      return <GeneralMiniIcon />;
    }

    case "battle_standard": {
      return <BattleStandardMiniIcon />;
    }

    case "wizard": {
      return <WizardMiniIcon />;
    }

    default: {
      return null;
    }
  }
}

export function WizardMiniIcon() {
  return (
    <Image
      src={wizard}
      width={20}
      height={20}
      alt="Wizard"
      className={css.miniIcon}
    />
  );
}

export function BattleStandardMiniIcon() {
  return (
    <Image
      src={battleStandard}
      width={20}
      height={20}
      alt="Battle Standard"
      className={css.miniIcon}
    />
  );
}

export function GeneralMiniIcon() {
  return (
    <Image
      src={general}
      width={20}
      height={20}
      alt="Army General"
      className={css.miniIcon}
    />
  );
}

import { KeywordRefData } from "../schema/latest.js";
import { pick } from "../lib/pick.js";
import { Footprint, assertFootprint } from "./footprint.js";
import { KeywordRef, toKeywordRef } from "./keyword-types.js";
import { Ruleset } from "./ruleset.js";
import { UnitType, assertUnitType } from "./unit-type.js";

export function stringifyUnitType(unitType: UnitType) {
  return pick(unitType, {
    [UnitType.LIGHT_INFANTRY]: "Light Infantry",
    [UnitType.RANGED_INFANTRY]: "Ranged Infantry",
    [UnitType.HEAVY_INFANTRY]: "Heavy Infantry",
    [UnitType.MONSTROUS_INFANTRY]: "Monstrous Infantry",
    [UnitType.LIGHT_CAVALRY]: "Light Cavalry",
    [UnitType.HEAVY_CAVALRY]: "Heavy Cavalry",
    [UnitType.WAR_WAGON]: "War Wagon",
    [UnitType.CHARIOT]: "Chariot",
    [UnitType.MONSTER]: "Monster",
    [UnitType.BEASTS]: "Beasts",
    [UnitType.ARTILLERY]: "Artillery",
  });
}

export function stringifyFootprint(f: Footprint) {
  return pick(f, {
    [Footprint.WIDE]: "Wide",
    [Footprint.NARROW]: "Narrow",
    [Footprint.HORDE]: "Horde",
  });
}

export type UnitProfileData = {
  type: string;
  points: number;
  speed: number;
  range: number;
  strikes: number;
  courage: number;
  footprint: string;
  keywords_cost_multiplier: number;
  default_keywords: KeywordRefData[];
  target_scores: Array<{
    vs_type: string;
    min_score: number;
  }>;
};

export class UnitProfile {
  type: UnitType;
  points: number;
  speed: number;
  range: number;
  courage: number;
  strikes: number;
  footprint: Footprint;
  keywordsCostMultiplier: number;
  defaultKeywords: KeywordRef[];
  targetScores: Array<{
    vsType: UnitType;
    minScore: number;
  }>;

  constructor(
    readonly ruleset: Ruleset,
    data: UnitProfileData,
  ) {
    assertUnitType(data.type);
    assertFootprint(data.footprint);

    this.type = data.type;
    this.points = data.points;
    this.speed = data.speed;
    this.range = data.range;
    this.courage = data.courage;
    this.strikes = data.strikes;
    this.footprint = data.footprint;
    this.keywordsCostMultiplier = data.keywords_cost_multiplier;
    this.defaultKeywords = data.default_keywords.map((data) => {
      return toKeywordRef(data);
    });
    this.targetScores = data.target_scores.map((entry) => {
      assertUnitType(entry.vs_type);

      return {
        vsType: entry.vs_type,
        minScore: entry.min_score,
      };
    });
  }
}

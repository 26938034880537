const locales = navigator.languages
  // The app is in English, so it doesn't make sense to pick out
  // non english locales for formatting preferences
  .filter((locale) => locale.startsWith("en"))

  // Always add en-GB as the fallback locale in case all locales
  // above were filtered out.
  .concat("en-GB");

type DateInput = string | number;

export function toDate(maybeDate?: DateInput) {
  if (maybeDate) {
    return new Date(maybeDate);
  }

  return null;
}

export function toLocaleDateString(
  maybeDate?: DateInput,
  options?: Intl.DateTimeFormatOptions,
) {
  return toDate(maybeDate)?.toLocaleDateString(locales, options) ?? "Unknown";
}

export function toLocaleString(
  maybeDate?: DateInput,
  options?: Intl.DateTimeFormatOptions,
) {
  return toDate(maybeDate)?.toLocaleString(locales, options) ?? "Unknown";
}

import { useCallback, useSyncExternalStore } from "react";

function persist<T extends object>(defaults: T, namespace: string = "") {
  const emitter = new EventTarget();
  const cache = new Map<string, unknown>();

  const proxy = new Proxy(defaults, {
    get(target, prop, receiver) {
      if (typeof prop !== "string") {
        return Reflect.get(target, prop, receiver);
      }

      // First check cache
      const cachedValue = cache.get(prop);
      if (cachedValue !== undefined) {
        return cachedValue as T;
      }

      // Now check local storage
      const value = localStorage.getItem(namespace + prop);
      if (typeof value === "string") {
        const parsedValue = JSON.parse(value) as T;
        cache.set(prop, parsedValue);
        return parsedValue;
      }

      // Set and return fallback otherwise
      const fallback = Reflect.get(target, prop, receiver);
      cache.set(prop, fallback);

      return fallback;
    },

    set(target, prop, value, receiver) {
      if (typeof prop !== "string") {
        return Reflect.set(target, prop, value, receiver);
      }

      localStorage.setItem(namespace + prop, JSON.stringify(value));
      cache.set(prop, value);
      emitter.dispatchEvent(new Event("change"));

      return true;
    },
  });

  return { proxy, emitter };
}

export function createUsePersistedState<T extends object>(object: T, options?: { namespace?: string }) {
  const { proxy, emitter } = persist(object, options?.namespace);

  function subscribe(callback: () => void) {
    emitter.addEventListener("change", callback);
    return () => {
      emitter.removeEventListener("change", callback);
    };
  }

  function usePersistedState<K extends keyof T>(key: K) {
    const getSnapshot = useCallback(() => proxy[key], [key]);
    const value = useSyncExternalStore(subscribe, getSnapshot);
    const setValue = useCallback((value: T[K]) => { proxy[key] = value; }, [key]);

    return [value, setValue] as const;
  }

  return usePersistedState;
}

import rerollLarge from "@/images/reroll-large.svg";
import { useDialog } from "@/ui/hooks/use-dialog";
import { Image } from "@/ui/image";
import { useNavContext } from "@/ui/nav";
import { useBattleStateOp } from "../battle-state/context";
import { ShareBattleDialog } from "../share-battle-dialog";
import * as css from "./style.css";

export function RerollAllButton() {
  const { setClosed } = useNavContext();
  const battleStateOp = useBattleStateOp();

  return (
    <button
      type="button"
      className={css.button}
      disabled={!battleStateOp.isSuccess}
      onClick={() => {
        if (battleStateOp.isSuccess) {
          battleStateOp.value.actions.rerollAll();
          setClosed();
        }
      }}
    >
      <span className={css.label}>Reroll all</span>

      <Image
        src={rerollLarge}
        alt=""
        className={css.icon}
        width={49}
        height={48}
      />
    </button>
  );
}

export function ShareButton() {
  const { store, isOpen, setOpen } = useDialog();
  const battleStateResult = useBattleStateOp();

  return (
    <>
      {isOpen && battleStateResult.isSuccess && (
        <ShareBattleDialog
          store={store}
          battleState={battleStateResult.value.state}
        />
      )}

      <button type="button" className={css.button} onClick={setOpen}>
        <span className={css.label}>Share battle</span>

        <Image
          src="/icons/share.svg"
          alt="Share"
          className={css.icon}
          width={40}
          height={40}
        />
      </button>
    </>
  );
}

import { Card, Deck } from "./deck.js";

export type FortuneData = {
  id: string;
  name: string;
  instructions: string;
};

export class Fortune {
  readonly id: string;
  readonly name: string;
  readonly instructions: string;
  readonly cards: Card[];

  constructor(data: FortuneData, deck: Deck) {
    this.id = data.id;
    this.name = data.name;
    this.instructions = data.instructions;
    this.cards = deck.take(2);
  }
}

import { isOneOf } from "../lib/typeguards.js";

export enum Footprint {
  WIDE = "WIDE",
  NARROW = "NARROW",
  HORDE = "HORDE",
}

export function isFootprint(type: string): type is Footprint {
  return isOneOf(type, Object.values(Footprint));
}

export function assertFootprint(type: string): asserts type is Footprint {
  if (!isFootprint(type)) {
    throw new Error(`${type} is not a valid Footprint.`);
  }
}

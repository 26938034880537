export function QRCode(props: { url: string }) {
  const params = new URLSearchParams({ url: props.url });
  return (
    <img
      src={`/api/qr?${params}`}
      alt=""
      style={{
        maxWidth: "12rem",
        aspectRatio: "1",
        margin: "0 auto 1rem",
        borderRadius: "0.5rem",
      }}
    />
  );
}

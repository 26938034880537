import jsc from "js-cookie";

export function getFromCookie<T = unknown>(name: string): T | null {
  const cookieValue = jsc.get(name);

  if (cookieValue) {
    try {
      return JSON.parse(cookieValue) as T;
    } catch {
      console.error("Could JSON.parse cookie value.");
      return null;
    }
  }

  return null;
}

export function removeCookie(name: string) {
  jsc.remove(name);
}

export function getStringArrayFromCookie(name: string) {
  return getFromCookie<string[]>(name) ?? [];
}

import { isOneOf } from "../lib/typeguards.js";

export enum UnitType {
  LIGHT_INFANTRY = "LIGHT_INFANTRY",
  RANGED_INFANTRY = "RANGED_INFANTRY",
  HEAVY_INFANTRY = "HEAVY_INFANTRY",
  MONSTROUS_INFANTRY = "MONSTROUS_INFANTRY",
  LIGHT_CAVALRY = "LIGHT_CAVALRY",
  HEAVY_CAVALRY = "HEAVY_CAVALRY",
  WAR_WAGON = "WAR_WAGON",
  CHARIOT = "CHARIOT",
  MONSTER = "MONSTER",
  BEASTS = "BEASTS",
  ARTILLERY = "ARTILLERY",
}

export function isUnitType(type: string): type is UnitType {
  return isOneOf(type, Object.values(UnitType));
}

export function assertUnitType(type: string): asserts type is UnitType {
  if (!isUnitType(type)) {
    throw new Error(`${type} is not a valid UnitType.`);
  }
}

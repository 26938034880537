import { HobgoblinDatabase } from "@/lib/db";
import { useQuery } from "@/ui/database-provider/hooks";
import { ReactNode } from "react";
import { ArmiesRulesetsContext } from "./context";

function getArmiesRulesets() {
  return (db: HobgoblinDatabase) => db.getArmiesRulesets();
}

export function ArmiesRulesetsFetcher(props: { children: ReactNode }) {
  const result = useQuery(getArmiesRulesets);
  return (
    <ArmiesRulesetsContext.Provider value={result}>
      {props.children}
    </ArmiesRulesetsContext.Provider>
  );
}

import { RerollSectionRibbon } from "@/battle/reroll-section-button";
import { SubtleHeading } from "@/battle/subtle-heading";
import { ReactNode } from "react";
import { ExclusionsIndicator } from "../exclusions-indicator";
import { SectionId } from "../../lib/user-settings";
import * as css from "./style.css";

export function BattleSection(props: {
  sectionId: SectionId;
  heading: string;
  children: ReactNode;
}) {
  const { children, sectionId } = props;

  return (
    <section className={css.section}>
      <div className={css.headerRow}>
        <SubtleHeading>
          {props.heading}
          <ExclusionsIndicator sectionId={sectionId} />
        </SubtleHeading>

        <RerollSectionRibbon sectionId={sectionId} />
      </div>

      {children}
    </section>
  );
}

export class ImmutableQueue<T> {
  private values: T[];
  private maxSize: number;

  constructor(values: T[], maxSize: number = Infinity) {
    this.values = values.slice(Math.max(0, values.length - maxSize));
    this.maxSize = maxSize;
  }

  get size() {
    return this.values.length;
  }

  shift(value: T) {
    return new ImmutableQueue([...this.values, value], this.maxSize);
  }

  includes(value: T) {
    return this.values.includes(value);
  }

  delete(value: T) {
    return new ImmutableQueue(
      this.values.filter((v) => v !== value),
      this.maxSize,
    );
  }

  *[Symbol.iterator]() {
    for (const value of this.values) {
      yield value;
    }
  }
}

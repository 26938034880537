import { useCallback, useEffect, useRef, useState } from "react";

type ElementsMap = Map<string, HTMLElement>;

/**
 * A convenience function that scrolls an element into view with predefined
 * configuration. If no element is provided it is a noop.
 */
function maybeScrollIntroView(element?: HTMLElement) {
  element?.scrollIntoView({
    behavior: "smooth",
    inline: "start",
    block: "nearest",
  });
}
export function useElementsMap() {
  const elementsRef = useRef<ElementsMap | null>(null);

  const getElementsMap = useCallback((): ElementsMap => {
    if (!elementsRef.current) {
      elementsRef.current = new Map();
    }

    return elementsRef.current;
  }, []);

  const setElementRef = useCallback(
    (id: string) => {
      return (element: HTMLDivElement) => {
        const map = getElementsMap();

        if (element) {
          map.set(id, element);
        } else {
          map.delete(id);
        }
      };
    },
    [getElementsMap],
  );

  return {
    getElementsMap,
    setElementRef,
  };
}

/**
 * A react hook that groups logic related to getting/setting state
 * related to visibility intersections.
 */
export function useIntersections() {
  type IntersectionsMap = Map<string, boolean>;

  const [intersections, setState] = useState<IntersectionsMap>(new Map());

  const setIntersections = useCallback(
    (changedIntersections: Array<{ id: string; isIntersecting: boolean }>) => {
      setState((prevIntersections) => {
        const nextIntersections = new Map(prevIntersections);

        for (const { id, isIntersecting } of changedIntersections) {
          nextIntersections.set(id, isIntersecting);
        }

        return nextIntersections;
      });
    },
    [],
  );

  const intersectionsValues = Array.from(intersections.values());

  const isSectionIntersecting = useCallback(
    (id: string) => !!intersections.get(id),
    [intersections],
  );

  return {
    /** A Map of section id to its visibility intersections. */
    intersections,

    /** Is the first section currently visible? */
    isFirstIntersecting: intersectionsValues[0],

    /** Is the last section the only one that is currently visible? */
    isOnlyLastIntersecting: intersectionsValues.every((value, index, array) => {
      if (index === array.length - 1) {
        // Last must be intersecting
        return value;
      }

      // All others must not be intersecting
      return !value;
    }),

    /** Checks whether a section is currently interesecting using section id. */
    isSectionIntersecting,

    /** An updater function that takes an array of new itersections. */
    setIntersections,
  };
}

/**
 * A collection of scroll handlers with stable identity (using useCallback).
 */
export function useScrollHandlers(
  getElementsMap: () => ElementsMap,
  intersections: Map<string, boolean>,
) {
  const scrollToSectionById = useCallback(
    (id: string) => {
      return () => {
        const map = getElementsMap();
        const element = map.get(id);
        maybeScrollIntroView(element);
      };
    },
    [getElementsMap],
  );

  const scrollToPreviousSection = useCallback(
    () => () => {
      const elements = getElementsMap();
      const array = Array.from(Array.from(intersections).entries());

      for (const [index, [_id, isIntersecting]] of array) {
        if (index === 0 && isIntersecting) {
          break;
        }

        if (isIntersecting) {
          const [_index, [id]] = array[index - 1];
          const element = elements.get(id);
          maybeScrollIntroView(element);
          break;
        }
      }
    },
    [getElementsMap, intersections],
  );

  const scrollToNextSection = useCallback(
    () => () => {
      const elements = getElementsMap();
      const array = Array.from(Array.from(intersections).entries());

      for (const [index, [_id, isIntersecting]] of array) {
        if (index + 1 === array.length && isIntersecting) {
          break;
        }

        if (isIntersecting) {
          const [_index, [id]] = array[index + 1];
          const element = elements.get(id);
          maybeScrollIntroView(element);
          break;
        }
      }
    },
    [getElementsMap, intersections],
  );

  return {
    scrollToSectionById,
    scrollToPreviousSection,
    scrollToNextSection,
  };
}

/**
 * A react hook that syncs the DOM to React state using intersection observer.
 */
export function useIntersectionObserverList<E extends HTMLElement>(
  getElementsMap: () => ElementsMap,
  setIntersections: (
    changed: { id: string; isIntersecting: boolean }[],
  ) => void,

  /**
   * The key is necessary to trigger recalculation of intersections
   * when the number of rendered items changes.
   */
  sectionsKey: string,
) {
  const containerRef = useRef<E | null>(null);

  useEffect(() => {
    const map = getElementsMap();

    const observer = new IntersectionObserver(
      (entries) => {
        setIntersections(
          entries.map((entry) => {
            return {
              id: entry.target.id,
              isIntersecting: entry.isIntersecting,
            };
          }),
        );
      },
      { root: containerRef.current, threshold: 0.6 },
    );

    for (const element of map.values()) {
      observer.observe(element);
    }

    return () => {
      for (const element of map.values()) {
        observer.unobserve(element);
      }
    };
  }, [getElementsMap, setIntersections, sectionsKey]);

  return {
    containerRef,
  };
}

export function useKeyboardShortcuts(
  scrollToPreviousSection: () => void,
  scrollToNextSection: () => void,
) {
  useEffect(() => {
    function handleKeyUp(this: Window, event: KeyboardEvent): void {
      if (event.key === "ArrowLeft") {
        scrollToPreviousSection();
        return;
      }

      if (event.key === "ArrowRight") {
        scrollToNextSection();
        return;
      }
    }

    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [scrollToNextSection, scrollToPreviousSection]);
}

export function useIntersectionObserver<E extends HTMLElement>(props: {
  /** Key determines when to re-run the intersection observers in case the DOM changes. */
  key: string;
}) {
  const { getElementsMap, setElementRef } = useElementsMap();

  const {
    intersections,
    isFirstIntersecting,
    isOnlyLastIntersecting,
    isSectionIntersecting,
    setIntersections,
  } = useIntersections();

  const { scrollToSectionById, scrollToPreviousSection, scrollToNextSection } =
    useScrollHandlers(getElementsMap, intersections);

  const { containerRef } = useIntersectionObserverList<E>(
    getElementsMap,
    setIntersections,
    props.key,
  );

  useKeyboardShortcuts(scrollToPreviousSection(), scrollToNextSection());

  return {
    containerRef,
    setElementRef,
    isFirstIntersecting,
    isOnlyLastIntersecting,
    isSectionIntersecting,
    scrollToSectionById,
    scrollToPreviousSection,
    scrollToNextSection,
  };
}

import * as css from "./style.css";

export function PreorderButton() {
  return (
    <a
      className={css.buy}
      target="_blank"
      rel="noreferrer"
      href="https://www.electi-studio.com/store/p/hobgoblin-standard-harcover?utm_source=indietabletopclub&utm_medium=hobgoblinbattlegen"
    >
      Pre-order the book
    </a>
  );
}

import { HobgoblinDatabase } from "@/lib/db";
import { Pending } from "@/lib/async-op";
import { createContext } from "react";
import { DatabaseOpenRequestOp } from "./types";

export const database = new HobgoblinDatabase();

// Expose on window to make it easier to debug IndexedDB
Object.assign(window, { hobgoblinDatabase: database });

export const DatabaseContext = createContext(database);

export const DatabaseOpenRequest = createContext<DatabaseOpenRequestOp>(
  new Pending(),
);

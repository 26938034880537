import { HobgoblinDatabase } from "@/lib/db";
import { fold } from "@/lib/async-op";
import { UserSettings } from "@/lib/user-settings";
import { useQuery } from "@/ui/database-provider/hooks";
import {
  useGameContentLookup,
  useResolveVersion,
} from "@/ui/game-content-provider";
import { ReactNode } from "react";
import { UserSettingsContext } from "./context";

function queryUserSettings(fallbackVersion: string) {
  return (db: HobgoblinDatabase) => db.getLatestUserSettings(fallbackVersion);
}

export function UserSettingsFetcher(props: { children: ReactNode }) {
  const { latestRuleset } = useGameContentLookup();
  const userSettingsDataOp = useQuery(queryUserSettings, latestRuleset.version);
  const rulesetOp = useResolveVersion(
    userSettingsDataOp.valueOrNull()?.ruleset_version,
  );
  const userSettingsOp = fold([userSettingsDataOp, rulesetOp]).mapSuccess(
    ([userSettingsData, ruleset]) => {
      return new UserSettings(ruleset, userSettingsData);
    },
  );

  return (
    <UserSettingsContext.Provider value={userSettingsOp}>
      {props.children}
    </UserSettingsContext.Provider>
  );
}

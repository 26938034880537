import * as css from "./style.css";
import { ReactNode } from "react";

export function SidenavTitle(props: { children: ReactNode }) {
  return (
    <div className={css.titleContainer}>
      <div className={css.divider} />
      <h1 className={css.title}>{props.children}</h1>
      <div className={css.divider} />
    </div>
  );
}

import { classNames } from "@/lib/class-list";
import { paragraph } from "@/ui/utils.css";

export function Paragraphs(props: { text: string; className?: string }) {
  return (
    <>
      {props.text.split(/\n+/).map((line, index) => {
        return (
          <p key={index} className={classNames(paragraph, props.className)}>
            {line}
          </p>
        );
      })}
    </>
  );
}

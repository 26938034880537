import { useSession } from "@/ui/session-provider/context";
import { getLoginUrl } from "@/ui/session-provider/ory";
import { useObserveSyncStorage } from "@/ui/sync-handler/sync-storage";
import { ToolExternalLink, ToolLink } from "@/ui/tool-link";
import * as css from "./style.css";

export function NavLinks() {
  return (
    <>
      <div className={css.navLabel}>Navigation</div>
      <ul>
        <li className={css.navListItem}>
          <ToolLink href="/army">Army Builder</ToolLink>
        </li>

        <li className={css.navListItem}>
          <ToolLink href="/battle">Chaos of War</ToolLink>
        </li>

        <li className={css.navListItem}>
          <ToolLink beta href="/fortune">
            Fortune Cards
          </ToolLink>
        </li>

        <li className={css.navListItem}>
          <ToolLink href="/settings">Settings</ToolLink>
        </li>

        <AuthButton />
      </ul>
    </>
  );
}

function AuthButton() {
  const { isLoggedIn } = useSession();
  const isSyncing = useObserveSyncStorage("isSyncing");
  const [latestLogItem] = useObserveSyncStorage("syncLog");
  const hasError = latestLogItem?.type === "SYNC_ERROR";

  if (!isLoggedIn) {
    return (
      <li className={css.navListItem}>
        <ToolExternalLink href="/join">Join</ToolExternalLink>
        <span className={css.divider}> / </span>
        <ToolExternalLink href={getLoginUrl()}>Log in</ToolExternalLink>
      </li>
    );
  }

  const status =
    isSyncing ? `syncing...`
    : hasError ? `sync error`
    : `synced`;

  return (
    <li className={css.navListItem}>
      <ToolLink href="/account">Account</ToolLink>
      <p className={css.syncStatus}>Status: {status}</p>
    </li>
  );
}

import { AnchorHTMLAttributes } from "react";
import { Link as WouterLink } from "wouter";

export function Link(
  props: AnchorHTMLAttributes<HTMLAnchorElement> & { href: string },
) {
  const { href, ...rest } = props;
  return (
    <WouterLink href={href}>
      <a {...rest} />
    </WouterLink>
  );
}

import { isNullish } from "@/lib/typeguards";
import { InfoPage } from "@/ui/info-page";
import { CoverImage, ListingItem, createDocsPages } from "../create-docs-pages";
import { useGameContentLookup } from "../game-content-provider";
import { ViewContainer } from "../max-width-container";
import { RulesetStatusCard, UpgradeRulesetCard } from "../upgrade-ruleset-card";

export type ChangelogPage = {
  id: string;
  href: string;
  data: {
    name: string;
    created_at: Date;
    author: string;
    upgrade_target_version?: string; // Semver
    cover_image_src?: string;
  };
  content: string;
};

function ChangelogPage(props: {
  coverImageSrc?: string;
  name: string;
  author: string;
  publishedDate: Date;
  content: string;
  upgradeToVersion?: string;
}) {
  const { latestRuleset } = useGameContentLookup();
  const publishedDate = props.publishedDate.toLocaleDateString("en-GB", {
    dateStyle: "long",
  });

  return (
    <InfoPage>
      {props.coverImageSrc && <CoverImage src={props.coverImageSrc} />}

      <h1>{props.name}</h1>

      <p>
        <em>{`${props.author}, ${publishedDate}`}</em>
      </p>

      <div dangerouslySetInnerHTML={{ __html: props.content }} />

      {isNullish(props.upgradeToVersion) ?
        null
      : props.upgradeToVersion === latestRuleset.version ?
        <UpgradeRulesetCard targetVersion={props.upgradeToVersion} />
      : <RulesetStatusCard
          title="Outdated ruleset"
          description="This ruleset is no longer the latest. You cannot upgrade to an outdated ruleset."
        />
      }
    </InfoPage>
  );
}

export const ChangelogPages = createDocsPages<ChangelogPage>({
  title: "Changelog",
  rootPath: "/changelog",
  renderListingPage(pages) {
    return (
      <ViewContainer>
        {pages.map((page, index) => {
          return (
            <ListingItem
              key={page.id}
              href={page.href}
              name={page.data.name}
              coverImageSrc={page.data.cover_image_src}
              createdAt={new Date(page.data.created_at)}
              showCover={index === 0}
            />
          );
        })}
      </ViewContainer>
    );
  },
  renderPage(page) {
    return (
      <ChangelogPage
        name={page.data.name}
        author={page.data.author}
        publishedDate={new Date(page.data.created_at)}
        content={page.content}
        upgradeToVersion={page.data.upgrade_target_version}
        coverImageSrc={page.data.cover_image_src}
      />
    );
  },
});

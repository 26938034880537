import { VersionChangeManager } from "@/oss/modern-idb";
import { IndexesV2 } from "../../schema/schema-v2.js";
import { SchemaV3 } from "../../schema/schema-v3.js";
import { SchemaV4, IndexesV3, ArmyDataV4 } from "../../schema/schema-v4.js";

type Manager = VersionChangeManager<SchemaV4 | SchemaV3, IndexesV3 | IndexesV2>;

export async function migrateToV4(manager: Manager) {
  const store = manager.transaction.objectStore("armies");

  for await (const cursor of store.openCursor()) {
    const army = cursor.value;

    if ("created" in army) {
      const timestamp = army.created;

      const armyV4: ArmyDataV4 = {
        ruleset_version: army.ruleset_version,
        id: army.id,
        name: army.name,
        description: army.description,
        created_ts: timestamp,
        updated_ts: timestamp,
        share_id: army.share_id,
        units: army.units,
        deleted: false,
      };

      await cursor.update(armyV4);
    }
  }

  store.deleteIndex("created_idx");
  store.createIndex("created_ts_idx", "created_ts");
  store.createIndex("updated_ts_idx", "updated_ts");
}

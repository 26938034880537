import { ArmyRecordData } from "@/schema/latest";

/**
 * Pulls changes data from the cloud.
 *
 * Setting the `since` param to null signals that this is the first pull on
 * this client and therefore deletes can be omitted.
 */
export async function pullChanges(props: { sinceTs: number | null }) {
  const params = new URLSearchParams({
    sinceTs: `${props.sinceTs ?? 0}`,
    omitDeleted: `${!props.sinceTs}`,
  });
  const response = await fetch(`/api/sync?${params}`);

  if (!response.ok) {
    const error: { message: string } = await response.json();
    throw new Error(`Pull failed: ${error.message}`);
  }

  const records: ArmyRecordData[] = await response.json();
  return records;
}

/**
 * Pushes data to the cloud.
 */
export async function pushAndPullChanges(props: {
  pushTs: number;
  pullSinceTs: number | null;
  armies: ArmyRecordData[];
}): Promise<{
  synced_count: number;
  armies: ArmyRecordData[];
}> {
  if (props.armies.length === 0) {
    return { synced_count: 0, armies: [] };
  }

  const response = await fetch(`/api/sync`, {
    method: "POST",
    body: JSON.stringify({
      armies: props.armies,
      syncedTs: props.pushTs,
      pullSinceTs: props.pullSinceTs ?? 0,

      // If pullSinceTs is null we are performing the first ever sync
      // on this client so it is safe to omit syncing deletes.
      pullOmitDeleted: !props.pullSinceTs,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const error: { message: string } = await response.json();
    throw new Error(`Push failed: ${error.message}`);
  }

  return await response.json();
}

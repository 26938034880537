import { VersionChangeManager } from "../../oss/modern-idb";
import { Indexes, Schema } from "../../schema/latest";

type Manager = VersionChangeManager<Schema, Indexes>;

export function createArmiesStore(manager: Manager) {
  const store = manager.createObjectStore("armies", { keyPath: "id" });
  store.createIndex("created_ts_idx", "created_ts");
  store.createIndex("updated_ts_idx", "updated_ts");
  store.createIndex("ruleset_version_idx", "ruleset_version");
}

export function createSettingStore(manager: Manager) {
  return manager.createObjectStore("settings", { keyPath: "ruleset_version" });
}

export function createFortuneStore(manager: Manager) {
  return manager.createObjectStore("fortunes", { keyPath: "ruleset_version" });
}

export function initDatabase(props: { manager: Manager }) {
  createArmiesStore(props.manager);
  createSettingStore(props.manager);
  createFortuneStore(props.manager);

  console.info("DB initialized.");
}

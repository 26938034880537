import { useDialog } from "@/ui/hooks/use-dialog";
import { Image } from "@/ui/image";
import { KeywordsHandler } from "@/ui/keywords-handler";
import { ModalDialog } from "@/ui/modal-dialog";
import { formatCard } from "../card-formatters";
import { FortuneCard } from "../fortune-card";
import * as css from "./style.css";

export function PlayingCard(props: {
  id: string;
  suite: string;
  value: string;
  name: string;
  description: string;
  isUsed: boolean;
  onAddToHand: () => Promise<void>;
}) {
  const { isOpen, setOpen, setClosed, store } = useDialog();
  const label = formatCard(props);

  return (
    <>
      {isOpen && (
        <ModalDialog className={css.dialog} store={store}>
          <KeywordsHandler>
            <div style={{ width: "20rem" }}>
              <FortuneCard
                isUsed={props.isUsed}
                name={props.name}
                cardId={props.id}
                description={props.description}
                buttonLabel="Add to hand"
                onClick={async () => {
                  await props.onAddToHand();
                  setClosed();
                }}
                label={label}
              />
            </div>
          </KeywordsHandler>
        </ModalDialog>
      )}

      <button
        type="button"
        onClick={setOpen}
        className={css.card[props.isUsed ? "used" : "available"]}
        title={label + (props.isUsed ? " (in hand)" : "")}
      >
        <Image
          src={`/cards/${props.id}.svg`}
          alt={label}
          width={225}
          height={315}
          className={css.cardImage}
        />
      </button>
    </>
  );
}

import { Battlefield } from "@/domain/battlefield";
import { Deployment } from "@/domain/deployment";
import { LookupMap } from "@/lib/lookup";
import { Scenario } from "@/domain/scenarios";
import { Twist } from "@/domain/twist";
import { isString } from "@/lib/typeguards";

export function getByRollResult(
  map: Map<number, string>,
  rollResult: number,
  name: string,
) {
  const entry = map.get(rollResult);

  if (entry === undefined) {
    throw new Error(`Could not find ${name} with roll result ${rollResult}.`);
  }

  return entry;
}

function rollD6() {
  return Math.floor(Math.random() * 6) + 1;
}

function rollD3() {
  return Math.floor(Math.random() * 3) + 1;
}

function roll2D6() {
  return rollD6() + rollD6();
}

function rollD66() {
  return Number(`${rollD6()}${rollD6()}`);
}

export type Entry = [string, string];

export function isEntry(value: unknown): value is Entry {
  return (
    Array.isArray(value) &&
    typeof value[0] === "string" &&
    typeof value[1] === "string"
  );
}

function toIdsSet<T extends { id: string }>(map: Map<unknown, T>) {
  return new Set(Array.from(map.values()).map((item) => item.id));
}

function checkRollViability(
  possible: Set<string>,
  excluded: Set<string> | null = null,
  minOptions: number = 1,
) {
  if (excluded) {
    const diff = new Set(possible);

    for (const excludedItem of excluded) {
      diff.delete(excludedItem);
    }

    if (diff.size < minOptions) {
      throw new Error("All possible options have been excluded");
    }
  }
}

export function getRandomScenario(
  scenariosTable: LookupMap<number, Scenario>,
  rerollIds?: Array<string | undefined> | null,
): Scenario {
  const rerollIdsSet = new Set(rerollIds?.filter(isString));
  checkRollViability(toIdsSet(scenariosTable), rerollIdsSet);

  let scenario = scenariosTable.getOrThrow(roll2D6());

  while (rerollIdsSet?.has(scenario.id)) {
    scenario = scenariosTable.getOrThrow(roll2D6());
  }

  return scenario;
}

export function getRandomBattlefield(
  battlefields: LookupMap<number, Battlefield>,
  rerollIds?: Array<string | undefined> | null,
): Battlefield[] {
  const maxRolls = rollD3();
  const rerollIdsSet = new Set(rerollIds?.filter(isString));
  const result: Battlefield[] = [];

  while (result.length < maxRolls) {
    const roll = rollD66();
    const battlefield = battlefields.getOrThrow(roll);

    // Reroll duplicates
    if (rerollIdsSet.has(battlefield.id)) {
      continue;
    }

    rerollIdsSet.add(battlefield.id);
    result.push(battlefield);
  }

  return result;
}

export function getRandomDeployment(
  deployments: LookupMap<number, Deployment>,
  rerollIds?: Array<string | undefined> | null,
): Deployment {
  const rerollIdsSet = new Set(rerollIds?.filter(isString));
  checkRollViability(toIdsSet(deployments), rerollIdsSet);

  let deployment = deployments.getOrThrow(rollD66());

  while (rerollIdsSet?.has(deployment.id)) {
    deployment = deployments.getOrThrow(rollD66());
  }

  return deployment;
}

export function getRandomTwist(
  twists: LookupMap<number, Twist>,
  rerollIds?: Array<string | undefined> | null,
): Twist {
  const rerollIdsSet = new Set(rerollIds?.filter(isString));
  checkRollViability(toIdsSet(twists), rerollIdsSet);

  let twist = twists.getOrThrow(rollD66());

  while (rerollIdsSet?.has(twist.id)) {
    twist = twists.getOrThrow(rollD66());
  }

  return twist;
}

import { memo, useEffect, useRef } from "react";

import { useKeywords } from "@/ui/keywords-handler";
import * as css from "./style.css";
import { useRuleset } from "../game-content-provider";
import { Ruleset } from "../../domain/ruleset";

function addReferences(
  text: string,
  ruleset: Ruleset,
  omitReferenceFor?: string,
) {
  function toId(name: string) {
    return name.toLowerCase().replaceAll(" [x]", "").replaceAll(/( |-)/g, "_");
  }

  function stringifyDataset(dataset: Record<string, string>) {
    const props = [];
    for (const [key, value] of Object.entries(dataset)) {
      if (value) {
        props.push(`${key}="${value}"`);
      }
    }
    return props.join(" ");
  }

  function stripValueNotation(name: string) {
    return name.replace(" [x]", "");
  }

  const allKeywordNames = ruleset.keywordsDefs.map((keyword) =>
    stripValueNotation(keyword.name),
  );

  const keywordNames =
    omitReferenceFor ?
      allKeywordNames.filter(
        (name) => name !== stripValueNotation(omitReferenceFor),
      )
    : allKeywordNames;

  const regex = new RegExp(
    `(?:(${keywordNames.join("|")})\\b(?: \\[([+-]?\\d+)\\])?)`,
    "g",
  );

  return text.replaceAll(regex, (match, name: string, value: string) => {
    const dataSet = {
      "data-keyword-id": toId(name),
      "data-keyword-value": value,
    };
    return `<i ${stringifyDataset(dataSet)}>${match}</i>`;
  });
}

function isValidTarget(
  target: unknown,
): target is HTMLElement & { dataset: { keywordId: string } } {
  return (
    target instanceof HTMLElement &&
    target.tagName === "I" &&
    !!target.dataset.keywordId
  );
}

function isValidKey(key: string) {
  return key === " " || key === "Enter";
}

export const Body = memo(function BodyMemo(props: {
  html: string;
  fontSize?: string;
  omitReferenceFor?: string;
}) {
  const ruleset = useRuleset();
  const handleKeywordClick = useKeywords();
  const html = addReferences(props.html, ruleset, props.omitReferenceFor);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const root = ref.current;

    const handleClick = (event: MouseEvent): void => {
      if (isValidTarget(event.target)) {
        handleKeywordClick(event.target);
      }
    };

    const handleKeyup = (event: KeyboardEvent): void => {
      if (isValidKey(event.key) && isValidTarget(event.target)) {
        handleKeywordClick(event.target);
      }
    };

    if (root) {
      root.addEventListener("click", handleClick);
      root.addEventListener("keyup", handleKeyup);

      const targets = root.querySelectorAll("i[data-keyword-id]");
      for (const target of targets) {
        if (isValidTarget(target)) {
          target.tabIndex = 0;
        }
      }
    }

    return () => {
      if (root) {
        root.removeEventListener("click", handleClick);
        root.removeEventListener("keyup", handleKeyup);
      }
    };
  }, [props.html, handleKeywordClick]);

  return (
    <div
      ref={ref}
      className={css.body}
      style={{ fontSize: props.fontSize ?? "1rem" }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
});
